<template>
    <div class="resetPassword xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span>新增<em>合集</em></span>
                    </div>
                    <div class="center-content">
                        <div class="iq-card-body">
                            <div class="from-box">
                                <el-form ref="videosForm" :model="videosForm" :rules="rules" label-width="150px">
                                    <el-form-item label="合辑名称" prop="name">
                                        <el-input type="textarea" v-model="videosForm.name" maxlength="50" show-word-limit>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="视频分类">
                                        <el-cascader v-model="videosForm.categoryId" :options="videosCategory"
                                            :props="{ checkStrictly: true, emitPath: false, value: 'id', label: 'name', children: 'children' }">
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="来源">
                                        <el-input class="w-18" v-model="videosForm.source"></el-input>
                                    </el-form-item>
                                    <el-form-item label="权限设置">
                                        <el-radio-group v-model="videosForm.limits">
                                            <el-radio :label="0">完全公开</el-radio>
                                            <el-radio :label="2">私密发布</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="合辑介绍">
                                        <el-input type="textarea" v-model="videosForm.introduce"></el-input>
                                    </el-form-item>
                                    <el-form-item label="合辑封面" prop="collectionUrl">
                                        <el-upload :headers="headers" :action="FileUploadUrl" class="avatar-uploader"
                                            :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                            <img v-if="videosForm.collectionUrl" :src="videosForm.collectionUrl"
                                                class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                            <!-- <div class="el-upload__tip" slot="tip">如果封面不上传，就默认视频的界面</div> -->
                                        </el-upload>
                                    </el-form-item>
                                    <el-form-item class="btn-bottom">
                                        <el-button @click="cancelBtn('videosForm')">取消</el-button>
                                        <el-button type="primary" @click="saveBtn('videosForm')">保存</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
</div>
</template>

<script>


import ftCom from '../../components/footer'
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { videoMain } from "../../components/video/video";
export default {
    name: "addcollect",
    computed: {
        headers() {
            return {
                "Authorization": 'Bearer' + ' ' + JSON.parse(localStorage.getItem('token')).value
            }
        }
    },
    components: {
        ftCom,
        nhCom,
        hdImg
    },
    data() {
        var video = new videoMain(this.TokenClient, this.Services.Statics)
        return {
            videoDomain: video,
            labelPosition: 'right',
            resetPassword: {
                oldPwd: '',
                newPwd: '',
                confirmPwd: ''
            },
            id: this.$route.query.videoId, //列表页id
            // collectionId: this.$route.query.collectionId,
            videosForm: {
                name: '',
                source: '',
                limits: 0,
                introduce: '',
                collectionUrl: '',
                categoryId: 1,
                collectionType: 1,
            },
            videosCategory: [],
            FileUploadUrl: this.Services.Statics + '/api/upload',
            rules: {
                name: [
                    { required: true, message: '请输入视频名称', trigger: 'blur' },
                ],
                collectionUrl: [
                    { required: true, message: '请上传合辑封面', trigger: 'blur' },
                ],
            },
            FileUploadUrl: `${this.Services.Statics}/api/upload`,
            // FileUploadUrl:'http://video.qyyl365.com'

        };
    },
    mounted() {
        this.LoadCategory();
        if (this.id) {
            this.LoadFrom()
        }
    },
    methods: {
        LoadFrom() {
            var _this = this;
            _this.videoDomain.GetVideoCollectionDetail(_this.id,
                function (data) {
                    _this.videosForm = data.data;

                }, function (err) { console.log(err); });
        },
        LoadCategory(success, error) {
            var _this = this;
            _this.videoDomain.getCategories(
                function (data) {
                    _this.videosCategory = data.data;
                    _this.videosForm.categoryId = _this.videosCategory[0].id
                },
                function (err) {
                    console.log(err);
                });
        },
        beforeAvatarUpload(file) {
            const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');

            if (!isJPG) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
            }
            return isJPG;
        },
        handleAvatarSuccess(res, file) {
            this.videosForm.collectionUrl = `${this.Services.Authorization}${res[0].completeFilePath}`;
            this.$refs['videosForm'].clearValidate(['collectionUrl'])
        },
        cancelBtn(formName) {
            this.$refs[videosForm].resetFields();
            this.$router.go(-1);
        },
        saveBtn(formName) {
            var _this = this;
            _this.videosForm.userName = this.$store.state.localData.userInfo.name
            _this.videosForm.userId = this.$store.state.localData.userInfo.id
            console.log(this.videosForm)
            var _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_this.videosForm.id) {
                        _this.videoDomain.getEditCollection(_this.videosForm,
                            function (data) {
                                _this.$refs[formName].resetFields();
                                _this.$router.go(-1);

                            },
                            function (err) {
                                console.log(err);
                            });
                    } else {
                        _this.videoDomain.getPutCollection(_this.videosForm,
                            function (data) {
                                _this.$refs[formName].resetFields();
                                _this.$router.go(-1);
                            },
                            function (err) {
                                console.log(err);
                            });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        }
    }
}
</script>

<style scoped>
@import "../../assets/css/footer.css";

.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.iq-card-body {
    height: 100%;
    padding: 15px;
    border-radius: 15px;
    background: #FFFFFF;
}

.from-box {
    position: relative;
    margin-top: 20px;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
    /*box-shadow:0px 10px 5px -10px #999;*/
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    float: left;
}

.personal-right {
    float: right;
    width: 900px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right>form {
    padding: 30px 15px;
    width: 460px;
}</style>
<style>.personal-center {
    display: none;
}</style>

let patientMain = function(tokenClient, serviceAddr) {
	let TokenClient = tokenClient
	let ServiceBaseAddress = serviceAddr
	/**
	 * 获取收藏列表数据
	 * @param {any} success
	 * @param {any} error
	 */
	this.getQuestionnaireReulst = function(searchName ,questionnaireId , questionnaireName,success, error) {
        var url = ServiceBaseAddress + '/api/Questionnaire/Reulst'
		if (searchName) {
            url += `/${searchName}`;
		} else {
            url += '/%20';
        }
        if (questionnaireId) {
			url += `/${questionnaireId}`;
		}
		if (questionnaireName) {
			url += `/${questionnaireName}`;
		}
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	this.getDiseasesSearch = function(category ,pageIndex , department, keyWord ,success, error) {
        var url = ServiceBaseAddress + '/api/Diseases/Search/0'
        if (category) {
			url += `/${category}`;
		}
		if (pageIndex) {
			url += `/${pageIndex}`;
		}
		if (department ) {
            url += `/${department }`;
		}
        if (keyWord) {
            url += `/${keyWord}`;
		} else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	this.TakeRequestList = function (keyWord, pageIndex,orgId, success, error) {
		 var url = ServiceBaseAddress + "/api/MZDrug/TakeRequestList"
		 if (keyWord) {
		 url += `/${keyWord}`
			} else {
			url += "/%20"
			}
			if (pageIndex) {
			url += `/${pageIndex}`
			}
			url += `/${orgId}`
			TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
			if (success) {
			var jsonObj = JSON.parse(data)
			success(jsonObj)
			}
			},
			error
			)
		}
	this.getPatientList = function (patientType, organitionId, userId, startTime, endTime, keyWord, pageIndex, success, error) {
		var url = ServiceBaseAddress + '/api/Patient/GetPatientListMyself';
        if (patientType) {
            url += `/${patientType}`;
		}
        if (organitionId || organitionId==0) {
            url += `/${organitionId}`;
		}
        if (userId) {
            url += `/${userId}`;
		}
        if (startTime) {
            url += `/${startTime}`;
        } else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        } else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	this.GetPatientDetail = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Patient/GetPatient/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	// 时间轴
	this.GePrescriptionTimelineData = function(patientDiagnoseId,success, error) {
		var url = ServiceBaseAddress + `/api/MZDrug/GePrescriptionTimeline/${patientDiagnoseId}`
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetPrintPrescriptionDetail = function(patientDiagnoseId, userId,id,success, error) {
		var url = ServiceBaseAddress + `/api/Patient/PrintPrescription/${patientDiagnoseId}/${userId}/${id}`
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	// 病例打印数据
	
	this.GetPatientDiagnoseDetail = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Patient/GetPatientDiagnose/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	
	this.GetDrugByOrgInTop10 = function(params, id, success, error) {
		var url = ServiceBaseAddress + '/api/MZDrug/FindDrugOrgList'
		if (params) {
            url += `/${params}`;
		} else {
            url += '/%20';
        }
		url += `/${id}`;
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	
	this.GetCheckItemsTop10 = function(params, state,orgId, success, error) {
		var url = ServiceBaseAddress + '/api/MZDrug/CheckItemsTop10'
		if (params) {
            url += `/${params}`;
		} else {
            url += '/%20';
        }
		if (state) {
            url += `/${state}`;
		} else {
            url += '/0';
        }
        url += `/${orgId}`;
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	
	// 获取个人常用处方
	this.GetdeleteMyCommonPrescription = function(id,success, error) {
		var url = ServiceBaseAddress + '/api/MZDrug/prescripId/' + id
		TokenClient.Delete(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.Questionnaire = function (success, error) {
		var url = ServiceBaseAddress + "/api/Questionnaire";
		tokenClient.Get(
		  url,
		  true,
		  null,
		  null,
		  function (data) {
			if (success) {
			  var jsonObj = JSON.parse(data);
			  success(jsonObj);
			}
		  },
		  error
		);
	  };

	this.getUserResultByUser = function(userId,keyWord,pageIndex, diseaseName,resultName,qId,customerPhone,success, error) {
		var url = ServiceBaseAddress + '/api/Questionnaire/UserResultByUser'
		if (userId) {
			url += `/${userId}`;
		}
		if (keyWord) {
            url += `/${keyWord}`;
		} else {
            url += '/%20';
        }
		if (pageIndex) {
			url += `/${pageIndex}`;
		}
		if (diseaseName) {
            url += `/${diseaseName}`;
		} else {
            url += '/%20';
        }
		if (resultName) {
            url += `/${resultName}`;
		} else {
            url += '/%20';
        }
		if (qId) {
			url += `/${qId}`;
		}
		if (customerPhone) {
			url += `/${customerPhone}`;
		}
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getResultLatest = function(customerPhone,success, error) {
		var url = ServiceBaseAddress + '/api/UserSelfDiagnosisRecord/ResultLatest/' + customerPhone
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getBZResultLatest = function(customerPhone,success, error) {
		var url = ServiceBaseAddress + "/api/UserSelfDiagnosisRecord/SelfDiagnosis/Latest/" + customerPhone;
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getBBResultLatest = function (customerPhone, success, error) {
    var url = ServiceBaseAddress + `/api/UserSelfDiagnosisRecord/TCMDisease/Latest/${customerPhone}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
		//   处方
		
		this.getRefusePrescription = function(state, startTime,endTime,keyWord,pageIndex,success, error) {
			var url = ServiceBaseAddress + '/api/MZDrug/RefusePrescription/' + state + '/' + startTime + '/' + endTime + '/' + keyWord + '/' + pageIndex
			TokenClient.Get(
				url,
				true,
				null,
				null,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
		}
		this.getWaitAuditPrescription = function(state,  startTime,endTime,keyWord ,pageIndex,success, error) {
			var url = ServiceBaseAddress + '/api/MZDrug/WaitAuditPrescription/' + state + '/' + startTime + '/' + endTime + '/' + keyWord + '/' + pageIndex
			TokenClient.Get(
				url,
				true,
				null,
				null,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
		}
		// 药剂师审核
		this.getWaitAuditPrescriptionData = function(state,  startTime,endTime,keyWord ,pageIndex,success, error) {
			var url = ServiceBaseAddress + '/api/MZDrug/GetWaitAuditPharmacist/' + state + '/' + startTime + '/' + endTime + '/' + keyWord + '/' + pageIndex
			TokenClient.Get(
				url,
				true,
				null,
				null,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
		}
		this.getMZIndex = function(success, error) {
			var url = ServiceBaseAddress + '/api/MZDrug/MZIndex'
			TokenClient.Get(
				url,
				true,
				null,
				null,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
		}
		this.MZPrescriptionDetail = function(id,success, error) {
			// var url = ServiceBaseAddress + '/api/MZDrug/MZPrescriptionDetail/' + id  + '/' + isfalse
			var url = ServiceBaseAddress + '/api/MZDrug/MZPrescriptionDetail/' + id 
			TokenClient.Get(
				url,
				true,
				null,
				null,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
		}
	// 获取个人常用处方
	this.GetMyCommonPrescription = function(type,keyWord, pageIndex, pageSize,success, error) {
		var url = ServiceBaseAddress + '/api/MZDrug/MyCommonPrescription'
		if (type || type==0) {
			url += `/${type}`;
		}
		if (keyWord) {
            url += `/${keyWord}`;
		} else {
            url += '/%20';
        }
		if (pageIndex) {
			url += `/${pageIndex}`;
		}
		if (pageSize) {
			url += `/${pageSize}`;
		}
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
		// 获取机构常用处方
		this.GetPrecriptionListOrg = function(type,keyWord, pageIndex,success, error) {
			var url = ServiceBaseAddress + '/api/MZDrug/PrecriptionListOrg'
			if (type || type==0) {
				url += `/${type}`;
			}
			if (keyWord) {
				url += `/${keyWord}`;
			} else {
				url += '/%20';
			}
			if (pageIndex) {
				url += `/${pageIndex}`;
			}
			TokenClient.Get(
				url,
				true,
				null,
				null,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
		}
		
		this.GetWaitAuditPrescription = function(success, error) {
			var url = ServiceBaseAddress + '/api/MZDrug/WaitAuditPrescription'
			TokenClient.Get(
				url,
				true,
				null,
				null,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
		}
		this.departmentDoctor = function(organitionIdId, success, error) {
			var url = ServiceBaseAddress + '/api/Department/Organization/Tree'
			if (organitionIdId) {
				url += `/${organitionIdId}`;
			}
			TokenClient.Get(
				url,
				true,
				null,
				null,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
		}
		this.GetDoctorAuditCountData = function(userIds, success, error) {
			var url = ServiceBaseAddress + '/api/MZDrug/GetDoctorAuditCount'
			if (userIds) {
				url += `/${userIds}`;
			}
			TokenClient.Get(
				url,
				true,
				null,
				null,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
		}
		this.GetPharmacistAuditCountData = function(userIds, success, error) {
			var url = ServiceBaseAddress + '/api/MZDrug/GetPharmacistAuditCount'
			if (userIds) {
				url += `/${userIds}`;
			}
			TokenClient.Get(
				url,
				true,
				null,
				null,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
		}
	// 药剂师
	this.GetAuditPharmacist = function(keyWord,departmentId,type,pageIndex,min,max, success, error) {
		var url = ServiceBaseAddress + '/api/Patient/AuditPharmacist'
		if (keyWord) {
            url += `/${keyWord}`;
		} else {
            url += '/%20';
        }
		if (departmentId || departmentId==0) {
			url += `/${departmentId}`;
		}
		if (pageIndex) {
			url += `/${pageIndex}`;
		}
		if (type) {
			url += `/${type}`;
		}
		if (min) {
            url += `/${min}`;
		} else {
            url += '/0';
        }
		if (max) {
            url += `/${max}`;
		} else {
            url += '/0';
        }
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	// 平台专家
	this.GetPlantformExpertDoctor = function(keyWord,departmentId, pageIndex,min,max, success, error) {
		var url = ServiceBaseAddress + '/api/Patient/GetPlantformExpertDoctor'
		if (keyWord) {
            url += `/${keyWord}`;
		} else {
            url += '/%20';
        }
		if (departmentId || departmentId==0) {
			url += `/${departmentId}`;
		}
		if (pageIndex) {
			url += `/${pageIndex}`;
		}
		if (min) {
            url += `/${min}`;
		} else {
            url += '/0';
        }
		if (max) {
            url += `/${max}`;
		} else {
            url += '/0';
        }
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetAuditDoctor = function(keyWord,departmentId, pageIndex, type,min,max, success, error) {
		var url = ServiceBaseAddress + '/api/Patient/AuditDoctor'
		if (keyWord) {
            url += `/${keyWord}`;
		} else {
            url += '/%20';
        }
		if (departmentId || departmentId==0) {
			url += `/${departmentId}`;
		}
		if (pageIndex) {
			url += `/${pageIndex}`;
		}
		if (type) {
			url += `/${type}`;
		}
		if (min) {
            url += `/${min}`;
		} else {
            url += '/0';
        }
		if (max) {
            url += `/${max}`;
		} else {
            url += '/0';
        }
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getHerbalPrescription = function( success, error) {
		var url = ServiceBaseAddress + '/api/HerbalPrescription/Categories'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	
	this.getOrderDiagnoseDetail = function(id,success, error) {
		var url = ServiceBaseAddress + '/api/MZDrug/OrderDiagnose/' + id
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getHerbalPrescriptionDetail = function(id,success, error) {
		var url = ServiceBaseAddress + '/api/HerbalPrescription/' + id
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetHerbalPrescriptionList = function(categoryId, pageIndex,keyWord, success, error) {
		var url = ServiceBaseAddress + '/api/HerbalPrescription/Search'
		if (categoryId) {
			url += `/${categoryId}`;
		}
		if (pageIndex) {
			url += `/${pageIndex}`;
		}
		if (keyWord) {
            url += `/${keyWord}`;
		} else {
            url += '/%20';
        }
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetDoctorAdviceList = function(keyWord, pageIndex, orgId, success, error) {
		var url = ServiceBaseAddress + '/api/MZDrug/DoctorAdviceList'
		if (keyWord) {
            url += `/${keyWord}`;
		} else {
            url += '/%20';
        }
		if (pageIndex) {
			url += `/${pageIndex}`;
		}
		url += `/${orgId}`;
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetPatientDiagnose = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Patient/GetPatientDiagnose/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	// 载入经典方
	this.GetIdConcetDetail = function(success, error) {
		var url = ServiceBaseAddress + '/api/connect'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' },
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )

		TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);

	}
	this.GetIdcardDetail = function(success, error) {
		var url = ServiceBaseAddress + '/api/readCert'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' },
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
	}
	this.getCompositionsByIds = function(item,success, error) {
		var url = ServiceBaseAddress + '/api/Drug/Manager/CompositionsByIds'
		TokenClient.Post(url, true, { 'Content-Type': 'application/json' },item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
	}

	this.getHerbalPrescriptionDetail = function(id,success, error) {
		var url = ServiceBaseAddress + '/api/HerbalPrescription/' + id
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}


	this.GetIdcardDetailTrue = function(success, error) {
		var url = ServiceBaseAddress + '/api/setRepeatRead?ISREPEAT=true'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' },
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
	}
	this.getLoadPrescription = function(orgId,item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/LoadPrescription/' + orgId
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.getHerbalPrescriptionMore = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/HerbalPrescription'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.GetIdcardDetail = function(success, error) {
		var url = ServiceBaseAddress + '/api/readCert'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' },
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
	}
	this.getupload = function (param,success, error) {
        var url = ServiceBaseAddress + '/api/File/upload';
            TokenClient.Post(url, true, { 'Content-Type': 'multipart/form-data' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	// 方剂添加
	this.getAddMZPrecriptionBatch = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/AddMZPrecriptionCommon'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.getAddLoadPrescriptionCommon = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/LoadPrescriptionCommon'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.getEditMZPrecriptionBatch = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/EditMZPrecription'
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.getPrecriptionDetail = function (Id, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/PrecriptionDetail/'+ Id;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	this.getSelfDiagnosisData = function (keyWord, pageIndex,success, error) {
        var url = ServiceBaseAddress + '/api/SelfDiagnosisPrimarySymptom/Manager/SelfDiagnosis/'+ keyWord + '/' + pageIndex;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	this.getTCMDiseaseData = function (keyWord, pageIndex,success, error) {
        var url = ServiceBaseAddress + '/api/SelfDiagnosisPrimarySymptom/Manager/TCMDisease/'+ keyWord + '/' + pageIndex;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	this.getSelfManagerData = function (id,success, error) {
		var url = ServiceBaseAddress + '/api/SelfDiagnosisPrimarySymptom/PrimarySymptomsDetail/'+ id;
        // var url = ServiceBaseAddress + '/api/SelfDiagnosisSyndrome/Manager/'+ id;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	
	this.getAuditMZPrescription = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/AuditMZPrescription'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.getResubmitPrescription = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/ResubmitPrescription'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.getStartAuditPrescription = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/StartAuditPrescription/' + item
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' },
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.getAuditPharmacist = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/AuditPharmacist'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	// 出药审核
	this.getMomentPrescription = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/MomentPrescription'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	// 直接出药
	this.getCompletePrescription = function(item, success, error) {
		var url = ServiceBaseAddress + '/api/MZDrug/CompletePrescription'
		TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.patientAdd = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/AddPatient'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.patientEdit = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/EditPatient'
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	/**
	 * 删除
	 * @param {*}
	 * @param {*} success
	 * @param {*} error
	 */
	this.DeletePatient = function (id, success, error) {
	  var url = ServiceBaseAddress + `/api/Patient/DeletePatient/${id}`
	  TokenClient.Delete(
		url,
		true,
		null,
		null,
		function (data) {
		  if (success) {
			var jsonObj = JSON.parse(data)
			success(jsonObj)
		  }
		},
		error
	  )
	}
	this.checkItemAdd = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/CheckItem'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.checkItemAlreadyAdd = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/CheckItemAlready'
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.patientedit = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/EditPatient'
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.AddDiagnose = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/AddDiagnose'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.AddMZDDiagnose = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/AddMZDiagnose'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.EditMZDDiagnose = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/EditMZDiagnose'
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.GetRXDetail = function (patientPrescriptionId, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription/GetDetail/'+ patientPrescriptionId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	// 获取个人常用处方
	// this.GetMyCommonPrescription = function (type, keyWord, pageIndex, pageSize, success, error) {
    //     var url = ServiceBaseAddress + '/api/MZDrug/MyCommonPrescription';
	// 	if (type || type==0) {
	// 		url += `/${departmentId}`;
	// 	}
	// 	if (keyWord) {
    //         url += `/${keyWord}`;
	// 	} else {
    //         url += '/%20';
    //     }
	// 	if (pageIndex) {
	// 		url += `/${pageIndex}`;
	// 	}
	// 	if (pageSize) {
	// 		url += `/${pageSize}`;
	// 	}
    //     TokenClient.Get(url, true, null, null,
    //         function (data) {
    //             if (success) {
    //                 var jsonObj = JSON.parse(data);
    //                 success(jsonObj);
    //             }
    //         }, error);
    // }
	
	this.getTodayPatient = function (keyword,success, error) {
        var url = ServiceBaseAddress + '/api/Patient/TodayPatient'
		if (keyword) {
			url += `/${keyword}`;
		} else {
			url += '/%20';
		}
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	this.getMZDiagnoseListPatient = function (pageIndex,success, error) {
        var url = ServiceBaseAddress + '/api/Patient/MZDiagnoseList/' + pageIndex
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	this.getMZDiagnosePationt = function (id,success, error) {
        var url = ServiceBaseAddress + '/api/Patient/MZDiagnosePationt/' + id
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	
    this.GetVideoCertificate = function(oldPassword, newPassword, success, error) {
		var url = 'http://192.168.22.249:8001/statics//api/Video/GetVideoCertificate'
		var param = {
			title: oldPassword,
			fileName: newPassword,
		}
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetUploadUserCertificateAudit = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/UserCertificateAudit/UploadUserCertificateAudit'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.GetSetUserSign = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/User/SetUserSign?signFile=' + item
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' },
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.GetAuditPayment = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/MZDrug/AuditPayment'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' },item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.GetIsLockScreen = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/User/IsLockScreen'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' },item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	/**
	 * 收藏
	 * @param {any} success
	 * @param {any} error
	 */
	this.Put = function(
		type,
		entityName,
		entityKey,
		entityId,
		entityType,
		success,
		error
	) {
		var url = ServiceBaseAddress + '/api/Collection/Put'
		var param = {
			type: type,
			entityName: entityName,
			entityKey: entityKey,
			entityId: entityId,
			entityType: entityType,
		}
		TokenClient.Post(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 删除
	 * @param {any} success
	 * @param {any} error
	 */
	this.Delete = function(referralId, success, error) {
		var url = ServiceBaseAddress + '/api/Collection/RemoveAll/' + referralId
		TokenClient.Delete(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	
    /**
     * 患者详情
     * @param patientId
     * @param success
     * @param error
     * @constructor
     */
    this.GetPatient = function (patientId, success, error) {
        var url = ServiceBaseAddress + '/api/Patient/GetPatient/'+patientId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
	this.GetPatientType = function (patientId, typeId,success, error) {
        var url = ServiceBaseAddress + '/api/Patient/GetPatient/'+patientId +'/' + typeId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

	
	this.GetCertificate = function(success, error) {
		var url = ServiceBaseAddress + '/api/User/GetIfUploadCertificate'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetUserSignData = function(success, error) {
		var url = ServiceBaseAddress + '/api/User/GetUserSign'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.GetPatientDiagnoseDetail = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Patient/GetPatientDiagnose/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
    
	this.MZPrintPrescription = function(patientDiagnoseId,userId,id, success, error) {
		var url = ServiceBaseAddress + `/api/Patient/PrintPrescription/${patientDiagnoseId}/${userId}/${id}`
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	// 常见症状
	this.GetSymptomListData = function(type,success, error) {
		var url = ServiceBaseAddress + `/api/MZSymptom/GetSymptomList/${type}/%20`
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
}

export { patientMain }

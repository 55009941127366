let FamilyFirstAid = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient;
  let ServiceBaseAddress = serviceAddr;

  /**
   * 获取健康常识首页
   * @param {any} success
   * @param {any} error
   */
  this.FamilyFirstAid = function (success, error) {
      var url = ServiceBaseAddress + '/api/FamilyFirstAid/Index';
      TokenClient.Get(url, true, null, null,
          function (data) {
              if (success) {
                  var jsonObj = JSON.parse(data);
                  success(jsonObj);
              }
          }, error);
  }
  this.FamilyFirstAidSort = function (pageIndex,sortId,keyWord,success, error) {
    var url = ServiceBaseAddress + '/api/FamilyFirstAid/List';
    if (pageIndex) {
        url += `/${pageIndex}`;
    }
    if (sortId) {
        url += `/${sortId}`;
    }
    if (keyWord) {
        url += `/${keyWord}`;
    } else {
        url += '/%20';
    }
    TokenClient.Get(url, true, null, null,
        function (data) {
            if (success) {
                var jsonObj = JSON.parse(data);
                success(jsonObj);
            }
}, error);
}

  /**
   * 获取健康常识详情
   * @param {any} success
   * @param {any} error
   */
  this.Detail = function (params,success, error) {
      var url = ServiceBaseAddress + '/api/FamilyFirstAid/' + params;
      TokenClient.Get(url, true, null, null,
          function (data) {
              if (success) {
                  var jsonObj = JSON.parse(data);
                  success(jsonObj);
              }
          }, error);
  }
}

export { FamilyFirstAid }
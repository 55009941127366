<template>
    <div class="resetPassword xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a></p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span>处方<em>详情</em></span>
                    </div>
                   <div class="iq-card-bodys">
                        <el-descriptions class="margin-top" :column="3" border>
                            <el-descriptions-item label="患者姓名">{{patientList.name}}</el-descriptions-item>
                            <el-descriptions-item label="年龄">{{patientList.age}}岁</el-descriptions-item>
                            <el-descriptions-item label="手机号">{{patientList.phone}}</el-descriptions-item>
                            <el-descriptions-item label="联系地址">{{patientList.region != null ? patientList.region.fullName : '-'}}</el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div v-if="drugdetail.auditStatus == 0" style="padding: 10px;">
                        审核状态：<span style="color:#1FB7A6">待审核</span>
                    </div>
                        <div v-if="drugdetail.auditStatus == -1" style="padding: 10px;">
                        审核状态：<span style="color:#1FB7A6">驳回</span>
                    </div>
                        <div v-if="drugdetail.auditStatus == 1" style="padding: 10px;">
                        审核状态：<span style="color:#1FB7A6">审核通过</span>
                    </div>
                        <div v-if="drugdetail.auditStatus == 2" style="padding: 10px;">
                        审核状态：<span style="color:#1FB7A6">待取药</span>
                    </div>
                    <div v-if="drugdetail.auditStatus == 3" style="padding: 10px;">
                        审核状态：<span style="color:#1FB7A6">完成</span>
                    </div>
                    <div class="" style="padding: 10px;">
                        审核时间：<span style="color:#1FB7A6">{{drugdetail.addTime}}</span>
                    </div>
                    <!-- 处方列表 -->


        <!-- 预览处方 -->
                <el-form label-width="100px">
                    <el-form-item label="主诉：">
                        <p>{{DiagnoseDetial.main}}</p>
                    </el-form-item>
                    <el-form-item label="现病史：">
                        <p v-if="DiagnoseDetial.current">{{DiagnoseDetial.current}}</p>
                        <p v-else>(空)</p>
                    </el-form-item>
                    <el-form-item label="既往史：">
                        <p v-if="DiagnoseDetial.past">{{DiagnoseDetial.past}}</p>
                        <p v-else>(空)</p>
                    </el-form-item>
                    <el-form-item label="过敏史：">
                        <p v-if="DiagnoseDetial.irritability">{{DiagnoseDetial.irritability}}</p>
                        <p v-else>(空)</p>
                    </el-form-item>
                    <el-form-item label="家族史：">
                        <p v-if="DiagnoseDetial.family">{{DiagnoseDetial.family}}</p>
                        <p v-else>(空)</p>
                    </el-form-item>
                    <el-form-item label="疾病诊断：">
                        <el-input type="textarea" :value="DiagnoseDetial.diseaseDiagnose" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="症状照片：">
                        <div>
                            <el-image
                                    v-for="item in DiagnoseDetial.diagnoseImgList" :key='item.id'
                                    style="width: 100px; height: 100px;margin-right: 15px;"
                                    :src="http + item.filePath"
                                    :preview-src-list='[http + item.filePath]'
                                    fit="fill"></el-image>
                        </div>
                    </el-form-item>
                    <el-form-item label="上传处方：">
                        <div>
                            <el-image
                                    v-for="item in DiagnoseDetial.prescriptionImgList" :key='item.id'
                                    style="width: 100px; height: 100px;margin-right: 15px;"
                                    :src="http + item.filePath"
                                    :preview-src-list='[http + item.filePath]'
                                    fit="fill"></el-image>
                        </div>
                    </el-form-item>
                </el-form>
            <p style="height: 50px;line-height: 50px;background-color: #ececec;margin: 15px 0;padding-left: 15px">{{drugdetail.prescriptionName}}</p>
            <el-table height="300"
                     :data="drugdetail.patientPrescriptionList"
                     style="width: 100%">
                <el-table-column
                        prop="patientComposition.compositionName"
                        label="成分">
                </el-table-column>
                <el-table-column
                        prop="dosage"
                        align="center"
                        label="用量">
                </el-table-column>
                <el-table-column
                        prop="remark"
                        align="center"
                        label="炮制方法">
                </el-table-column>
            </el-table>
            <el-form label-width="120px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="剂量" prop="dosage">
                            <el-input v-model.number="drugdetail.dosage" type="number" placeholder="单位（剂）" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用法用量" prop="number">
                            <el-input v-model.number="drugdetail.number" type="number" placeholder="（次/日）" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="快递方式">
                    <el-radio-group v-model="drugdetail.isSelef" disabled>
                        <el-radio :label="true">自取</el-radio>
                        <el-radio :label="false">快递</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="其他备注">
                    <el-input v-model="drugdetail.remark" type="textarea" disabled></el-input>
                </el-form-item>
            </el-form>
            </div>
        </div>
        <ft-com></ft-com>
    </div>
    </div>
</template>

<script>
    import ftCom from '../../components/footer'
    import nhCom from "../../components/navheader";
    import hdImg from "../../components/headerImg";
    import {patientMain} from "../../components/patienTmanagement/patienTmanagement";
    import { Region } from "../../components/region/Region";
    import { Drug } from '../../components/Drug/Drug'
    export default {
        name: "addRecord",
        computed: {
            headers() {
                return {
                    "Authorization": 'Bearer' + ' ' + JSON.parse(localStorage.getItem('token')).value
                }
            }
        },
        components: {
            ftCom,
            nhCom,
            hdImg
        },
        data() {
            var region = new Region(this.TokenClient, this.Services.Authorization);
            var patient= new patientMain(this.TokenClient, this.Services.Authorization)
            var drug= new Drug(this.TokenClient, this.Services.Drug)
            return {
                patientDomain:patient,
                Region: region,
                 DrugDomain:drug,
                FileUploadUrl: this.Services.Statics+'/api/upload',
                patientId: this.$route.query.patientId, //列表页id
                patientDiagnoseId: this.$route.query.patientDiagnoseId, //列表页id
                ImageContent: [],//图片列表
                ImageContentList: [],//图片上传控件加载的数据
                DiagnoseContent: [],//图片列表
                patientList:[],
                RXForm: {
                    userId: this.$store.state.localData.userInfo.id,
                    patientId: this.$route.query.patientId,
                    patientDiagnoseId: this.$route.query.patientDiagnoseId,
                    prescriptionName: '',
                    isSelef: true,
                    dosage: null,
                    number: null,
                    prescriptionType: 1,
                    compositions: [
                        {
                            edit: true,
                            compositionName: '',
                            remark: '',
                            dosage: '',
                            category: null,
                            categoryName: null,
                            compositionAlias: null,
                            compositionId: null,
                        }
                    ],
                },
                rules: {
                    prescriptionName: [
                        {required: true, message: '请输入处方名称', trigger: 'blur'},
                    ],
                    dosage: [
                        {required: true, message: '请输入剂量', trigger: 'blur'},
                    ],
                    number: [
                        {required: true, message: '请输入用法用量', trigger: 'blur'},
                    ],
                },
                EditDrugCompositio:[],
                drawer: false,
                direction: 'rtl',
                Keyword: '',
                herbalNavList:[],
                herbalData:[],
                PageIndex: 1,
                PageTotal: 1,
                PageSize:15,
                StaffTotal: 0,
                loading: false,
                loadings: false,
                herbalDetail:{},
                categoryId: 0,
                searchId: '0',
                preview: false,
                id:'',
                drugdetail:{},
                DiagnoseDetial:{},
                http: this.Services.ossUpload,
            };
        },
        mounted(){
            // this.getList()
            // this.getRX()
            this.Getdetail()
        },
        methods: {
            Getdetail(){
                var _this = this;
                _this.DrugDomain.GetPrescriptionDetail(_this.$route.query.id,function (data) {
                                   var f = data.data.addTime.split('T')
						data.data.addTime = f[0] + ' ' + f[1].slice(0,8)
                     _this.drugdetail = data.data
                    _this.getList(data.data.patientId)
                    _this.getseeDiagnose(data.data.patientDiagnoseId)

                },function (error) {
                    // _this.$message.error('修改失败！旧密码不正确，请重新输入。');
                })
			},
            getseeDiagnose(e){
                var _this = this;
	           _this.patientDomain.GetPatientDiagnose(e,function (data) {
                    var f = data.data.addTime.split('T')
						data.data.addTime = f[0] + ' ' + f[1].slice(0,8)
                    _this.DiagnoseDetial = data.data
                },function (error) {
                    // _this.$message.error('修改失败！旧密码不正确，请重新输入。');
                })
			},
            
            getList(patientId) {
                var _this = this;
                _this.patientDomain.GetPatientDetail(patientId,function (data) {
                    _this.patientList = data.data
                    _this.Region.getRegions(_this.patientList.id,function(data){
                        _this.patientList.region = data.data
                       })

                },function (error) {
                    // _this.$message.error('修改失败！旧密码不正确，请重新输入。');
                })
            },
        }
    }
</script>

<style scoped>
    @import "../../assets/css/footer.css";

    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg") center no-repeat;
    }
    .patientheard{
        display: flex;
        align-items: center;
    }
    .patientBox {
        padding: 21px 42px;
        color: #808080
    }
    .patient {
        padding: 21px 0  15px 0;
        border-bottom: 1px dashed #CCCCCC;
    }
    .pList{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .pListLeft {
           display: flex;
        align-items: center;
    }
    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
    /**/
    .personal-content{
        background: #f7f7f7;
        padding: 40px 0;
    }
    .personal-box{
        width: 1200px;
        margin: 0 auto;
    }
    .personal-left{
        float: left;
    }
    .personal-right{
        float: right;
        width: 900px;
        background: #FFFFFF;
        box-sizing: border-box;
    }
    .personal-right-tit{
        border-bottom: 1px solid #e5e5e5;
        padding: 20px;
    }
    .personal-right-tit .iconfont{
        color: #04a290;
        font-size: 24px;
        margin-right: 5px;
    }
    .personal-right-tit span{
        display: inline-block;
        font-size: 24px;
        font-weight: 700;
        color: #333333;
    }
    .personal-right-tit span em{
        font-style: normal;
        color: #04a290;
    }
    .personal-right>form{
        padding: 30px 15px;
        width: 460px;
    }
        .drawer_body{
        padding: 0 20px 20px;
    }
    .category_box{
        padding: 15px 0;
        border-bottom: 1px solid #eeeeee;
    }
    .category_box .el-button{
        margin: 5px;
    }
    .category_box .el-button.active{
        color: #FFF;
        background-color: #5C83F2;
        border-color: #5C83F2;
    }
</style>
<style>
    .personal-center{
        display: none;
    }
</style>

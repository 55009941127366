<template>
  <div class="inteBox">
    <intNavheader activeNameCheck="second"></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <!-- <div :class="userInfo.isAuditDiagnose == 'True' ? 'titleTopCenterLeftBox' : 'titleTopCenterLeftBoxno'" @click="goDoctor">处方审核</div> -->
        <waitNumber :waitDataTotalNumber='waitDataTotal'></waitNumber>
        
        <div class="titleTopCenterLeftBox" @click="gonewDoctor">新增患者</div>
      </div>
      <div class="titleTopCenterCenter">
        <div class="titleTopCenterCenterTop" v-if="!addtoNew">
          <div class="Content1Title">患者列表</div>
          <div class="flex-l" style="margin-top: 20px">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px"> </el-date-picker>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="患者名称/手机号/身份证号" @keyup.enter.native="patientSearch" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="patientSearch()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table :data="patientList" stripe style="width: 100%" height="calc(100vh - 295px)" :header-cell-style="{ background: '#F5F7FC' }">
            <el-table-column type="index" label="编号" width="50"></el-table-column>
            <el-table-column prop="name" label="姓名">
              <template slot-scope="scope">
                <div class="titleTopLeftTableC" @click="goDetail(scope.row)">{{ scope.row.name }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="sex" label="性别" align="center">
              <template slot-scope="scope">
                <div class="thwidth">{{ scope.row.sex ? "男" : "女" }}</div>
                <!-- <div class="titleTopLeftTableC" @click="goDetail">{{scope.row.name}}</div> -->
              </template>
            </el-table-column>
            <el-table-column prop="age" label="年龄" align="center">
              <template slot-scope="scope">
                <div class="thwidth">{{ scope.row.age }}</div>
                <!-- <div class="titleTopLeftTableC" @click="goDetail">{{scope.row.name}}</div> -->
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="联系方式" align="center">
              <template slot-scope="scope">
                <div class="thwidth">{{ scope.row.phone }}</div>
                <!-- <div class="titleTopLeftTableC" @click="goDetail">{{scope.row.name}}</div> -->
              </template>
            </el-table-column>
            <el-table-column prop="idNo" label="身份证号" align="center"></el-table-column>
            <el-table-column prop="diseaseName" label="基础病" align="center"></el-table-column>
            <el-table-column prop="address" label="最近诊断" align="center">
              <template slot-scope="scope">
                <div class="thwidth">{{ scope.row.patientDiagnose.length != 0 ? scope.row.patientDiagnose[scope.row.patientDiagnose.length - 1].diseaseDiagnose : "暂无诊断" }}</div>
                <!-- <div class="titleTopLeftTableC" @click="goDetail">{{scope.row.name}}</div> -->
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="添加时间" align="center" width="150px">
              <template slot-scope="scope">
                {{ scope.row.addTime | timefilters }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" align="center">
              <template slot-scope="scope">
              
                <span class="danger optionBtn" @click="RemoveBtn(scope.row)">删除</span>
                <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">新建处方</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
        </div>
        <div v-else class="titleTopCenterCenterTop" style="padding-right: 40%; overflow-y: auto; padding-bottom: 20px; height: calc(100% - 30px)">
          <el-page-header @back="goBack" :content="type12 == 1 ? '修改患者' : '新增患者'"> </el-page-header>
          <!-- <div class="Content1Title"><i class="el-icon-back" @click="goBack"></i> {{ type12 == 1 ? "修改患者" : "新增患者" }}</div> -->
          <el-form :model="patientForm" ref="patientForm" :rules="patientFormRules" label-position="top">
            <div class="flex-l">
              <el-form-item prop="name" label="姓名">
                <el-input class="ageInput" v-model="patientForm.name"></el-input>
              </el-form-item>

              <el-form-item prop="age" label="年龄">
                <div class="flex-l">
                  <el-input style="width: 80%" v-model="patientForm.age"></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="sex" label="性别">
                <div class="flex-l-s" @click="goAddPatientSex(patientForm.sex)">
                  <div :class="patientForm.sex == true ? 'typeChecked' : 'notypeChecked'">男</div>
                  <div :class="patientForm.sex == false ? 'typeChecked' : 'notypeChecked'" style="margin-left: 10px">女</div>
                </div>
              </el-form-item>
            </div>
            <el-form-item label="身份证号" rop="idNo"> <el-input class="ageInput" style="margin-right: 10px" v-model="patientForm.idNo"></el-input><el-button type="primary" @click.prevent="removeDomain(domain)">查看身份证</el-button> </el-form-item>
            <el-form-item prop="phone" label="电话号码">
              <el-input v-model="patientForm.phone"></el-input>
            </el-form-item>
            <el-form-item prop="address" label="地址">
              <el-input v-model="patientForm.address"></el-input>
            </el-form-item>
            <el-form-item prop="diseaseName" label="基础病">
              <el-input v-model="patientForm.diseaseName"></el-input>
            </el-form-item>
            <el-form-item style="text-align: right; margin-top: 20px">
              <el-button v-if="type12 == 1" type="primary" @click="editPatientForm('patientForm')">保存</el-button>
              <el-button v-else type="primary" @click="addPatientForm('patientForm')">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="titleTopLine"></div>
  </div>
</template>
<script>
import intNavheader from "../../components/intNavheader";
import waitNumber from "../../components/waitNumber";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
export default {
  components: {
    intNavheader,
    waitNumber
  },
  data() {
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    return {
      patientDomain: patient,
      drugpatientDomain: drugpatient,
      patientType: 1, // 固定值
      organitionId: 0, // 固定值
      userInfo: this.$store.state.localData.userInfo,
      patientFormRules: {
        name: [{ required: true, message: "请输入患者姓名", trigger: "blur" }],
        age: [{ required: true, message: "请输入患者年龄", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入患者联系方式", trigger: "blur" },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" },
        ],
      },
      searchForm: {
        keyWord: "",
        datepicker: "",
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      patientKeyWord: "",
      type12: 0,
      pageIndex: 1,
      patientTotal: 0,
      patientList: [],
      addtoNew: false,
      inputValue: "",
      inputValue2: "",
      ageOptions: [
        {
          value: "岁",
          label: "岁",
        },
        {
          value: "月",
          label: "月",
        },
        {
          value: "天",
          label: "天",
        },
      ],
      num: "",
      patientForm: {
        name: "",
        age: "",
        phone: "",
        idNo: "",
        address: "",
        sex: true,
        diseaseName:''
      },
      activeName: "first",
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      waitDataTotal:0,
      detailUserType:this.$store.state.localData.userInfo.detailUserType,
      orgexpertId:  JSON.parse(localStorage.getItem('ExpertOrganization')) ? JSON.parse(localStorage.getItem('ExpertOrganization')).organizationId : this.$store.state.localData.userInfo.organizationId,
      orgexpertName: JSON.parse(localStorage.getItem('ExpertOrganization')) ? JSON.parse(localStorage.getItem('ExpertOrganization')).organizationName : this.$store.state.localData.userInfo.organizationName,
    };
  },
  mounted() {
    if (this.$route.query.type == 1) {
      this.type12 = this.$route.query.type;
      this.addtoNew = true;
      this.getDetail();
    }
    this.pageIndex = 1;
    this.getPatientListdata();
    this.gettWaitData()
  },
  beforeDestory(){
    this.addtoNew = false
  },
  methods: {
      gettWaitData() {
      var _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
        },
        function (error) {
          _this.waitDataTotal = 0;
        }
      );
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex;
      this.getPatientListdata();
    },
    getDetail() {
      var _this = this;
      _this.patientDomain.GetPatientDetail(
        _this.$route.query.id,
        function (data) {
          _this.patientForm = data.data;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    //详情获取
    goDoctor() {
      if (this.userInfo.isAuditDiagnose == "True") {
        this.$router.push({
          name: "PremangePatientList",
        });
      } else {
        this.$message({
          message: "暂无审方权限,请联系管理员添加",
          type: "warning",
        });
      }
      // this.doctorDialogVisible = true
      // this.GetAuditDoctorData()
    },
    patientSearch() {
      this.pageIndex = 1;
      this.getPatientListdata();
    },
    handleCurrentChange(pageNum) {
      this.patientcurrentPage = pageNum;
      this.pageIndex = pageNum;
      this.getPatientListdata();
    },
    goAddPatientSex() {
      this.patientForm.sex = !this.patientForm.sex;
    },
    getPatientListdata() {
      var _this = this;
      _this.patientList = [];
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      _this.patientDomain.getPatientList(
        _this.patientType,
        _this.organitionId,
        _this.userInfo.id,
        item.startTime,
        item.endTime,
        item.keyWord,
        _this.pageIndex,
        function (data) {
          _this.patientList = data.data.results;
          for (var i = 0; i < _this.patientList.length; i++) {
            var f = _this.patientList[i].addTime.split("T");
            _this.patientList[i].addTime = f[0] + " " + f[1].slice(0, 8);
          }
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    addPatientForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          if (_this.detailUserType == '4') {
              _this.patientForm.organizationId = _this.orgexpertId
              _this.patientForm.organizationName = _this.orgexpertName
          }
          _this.patientForm.userId = _this.userInfo.id;
          _this.patientForm.PatientType = 1;
          _this.patientDomain.patientAdd(
            _this.patientForm,
            function (data) {
              (_this.patientForm = {
                name: "",
                age: "",
                phone: "",
                idNo: "",
                address: "",
                sex: true,
                diseaseName:'',
                organizationId:0
              }),
                _this.$message({
                  message: "添加成功",
                  type: "success",
                });
              _this.pageIndex = 1;
              _this.getPatientListdata();
              _this.addtoNew = false;
            },
            function (error) {
              console.log(error);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editPatientForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.patientForm.userId = _this.userInfo.id;
          _this.patientForm.PatientType = 1;
          _this.patientDomain.patientEdit(
            _this.patientForm,
            function (data) {
              (_this.patientForm = {
                name: "",
                age: "",
                phone: "",
                idNo: "",
                address: "",
                sex: true,
                diseaseName:'',
                organizationId:0
              }),
                _this.$message({
                  message: "修改成功",
                  type: "success",
                });
              _this.type12 = 0;
              _this.getPatientListdata();
              _this.$router.push({
                name: "intPatientList",
              });
              _this.addtoNew = false;
              window.reload();
            },
            function (error) {
              console.log(error);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goDetail(item) {
      this.$router.push({
        name: "intPatientListDtail",
        query: {
          id: item.id,
        },
      });
    },
    goBack() {
      this.addtoNew = false;
    },
    gonewDoctor() {
      this.addtoNew = !this.addtoNew;
    },
    RemoveBtn(e) {
      console.log(e)
      var _this = this
      _this.$confirm('是否删除此信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning'
        })
        .then(() => {
          _this.patientDomain.DeletePatient(
            e.id,
            function (data) {
              _this.$message({
                type: 'success',
                message: '删除成功!',
              })
              _this.getPatientListdata();
            },
            function (err) {
              console.log(err)
            }
          )
        })
        .catch(() => {})
    },
    handleClickTop(e, e1) {
      if (e.label == "患者病历") {
        this.$router.push({
          name: "interrogationIndex",
        });
      }
      if (e.label == "患者列表") {
        this.$router.push({
          name: "intPatientList",
        });
      }
      if (e.label == "个人常用处方") {
        this.$router.push({
          name: "preintPatientList",
        });
      }
      if (e.label == "处方管理") {
        this.$router.push({
          name: "PremangePatientList",
        });
      }
    },
  },
};
</script>
<style scoped>
.pageBox {
  text-align: center;
  margin-top: 10px;
}
.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}
.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}
.titleTopLeftImg {
  width: 123px;
  height: 24px;
}
.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}
.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}
.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}
.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}
.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.titleTopRight div {
  margin-left: 10px;
}
.titleTopLine {
  width: 100%;
  height: 12px;
}
.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}
.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  height: calc(100vh - 64px - 24px);
  overflow: auto;
}
.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding: 10px 40px;
}
.flex-l {
  display: flex;
  align-items: center;
}
.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Content1Title {
  padding: 20px 40px;
  padding-left: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 0;
  text-align: left;
}
.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}
.ageInput {
  width: 80%;
}
.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}
.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

::v-deep .el-pagination {
  padding: 10px 5px;
}
.el-page-header {
  line-height: 73px;
  /* padding-left: 20px; */
}
</style>

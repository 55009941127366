<template>
    <div :class="Services.ClientId == 'CloudExpertWeb' ? 'loginExpert xi' : 'login xi'">
        <div v-if="Services.ClientId == 'CloudExpertWeb'">
            <div class="loginExpertBox">
                <div class="ExpertBox">
                    <div class="ExpertLogo">
                        <img src="../assets/img/iconLogin.png" style="margin-top: 15px;" alt="" srcset="">
                    </div>
                    <div class="ExpertSize">专家登录</div>
                </div>
                <el-form :model="passForm" status-icon ref="passForm" label-width="0px">
                    <el-form-item prop="telNum">
                        <el-input v-model="passForm.username" autocomplete="off" placeholder="请输入账号" style="width: 480px;background: #EFF2F6;border-radius: 14px;">
                             <template slot="prepend"><i slot="prefix" class="el-icon-user" style="color:#333333;font-size:14px"></i></template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="pass">
                        <el-input type="password" v-model="passForm.password" autocomplete="off"
                            placeholder="请输入密码"  style="width: 480px;background: #EFF2F6;border-radius: 14px;">
                            <template slot="prepend"><i slot="prefix" class="el-icon-lock" style="color:#333333;font-size:14px"></i></template>
                            
                            </el-input>
                    </el-form-item>
                    <div class="right" style="margin-bottom:50px">
                        <el-checkbox v-model="isRemember">记住密码</el-checkbox>
                    </div>
                    <el-form-item>
                        <el-button style="width:100%" type="primary" @click="submitForm()">登录</el-button>
                    </el-form-item>
                    <div class="clearfix">
                        <div class="left">
                            <el-checkbox v-model="Agreement">已阅读并同意相关<router-link target="_blank"
                                    to="/company/UserAgreement"><span style="color: #1fb7a6;">《服务协议》</span></router-link>和<router-link
                                    target="_blank" to="/company/disclaimer"><span style="color: #1fb7a6;">《隐私政策》</span></router-link>
                            </el-checkbox>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div v-else class="login-wrap">
            <div class="side-img">
                <img src="../assets/img/side_img.png" alt="">
            </div>
            <div class="before clearfix">
                <div class="before-left left flex-center-columns">
                    <div class="before-left-box flex-space-between-columns">
                        <h3>智能辅助诊疗系统</h3>
                        <div class="line"></div>
                        <div>
                            <h4>专注客户体验，创新引领技术</h4>
                            <p class="slogan">专注<span>•</span>一流<span>•</span>协作<span>•</span>创新</p>
                        </div>

                        <button @click="loginAnimation">登录</button>
                    </div>
                </div>
                <div class="before-right right">
                    <img src="../assets/img/before_right_img.png" alt="">
                </div>
            </div>
            <div class="after clearfix">
                <div class="login-con left flex-center">
                    <div class="login-from">
                        <el-tabs v-model="activeName">
                            <el-tab-pane label="密码登录" name="first">
                                <el-form :model="passForm" status-icon ref="passForm" label-width="0px">
                                    <el-form-item prop="telNum">
                                        <el-input v-model="passForm.username" autocomplete="off" placeholder="手机号">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item prop="pass">
                                        <el-input type="password" v-model="passForm.password" autocomplete="off"
                                            placeholder="密码"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="submitForm()">登录</el-button>
                                    </el-form-item>
                                    <div class="clearfix">
                                        <div class="left">
                                            <el-checkbox v-model="Agreement">已阅读并同意相关<router-link target="_blank"
                                                    to="/company/UserAgreement">《服务协议》</router-link>和<router-link
                                                    target="_blank" to="/company/disclaimer">《隐私政策》</router-link>
                                            </el-checkbox>
                                        </div>
                                        <div class="right">
                                            <el-checkbox v-model="isRemember">记住密码</el-checkbox>
                                        </div>
                                    </div>
                                </el-form>
                            </el-tab-pane>
                            <!--<el-tab-pane label="短信登陆" name="second">
                                <el-form :model="codeForm" ref="codeForm" label-width="0px">
                                    <el-form-item prop="mobile">
                                        <el-input v-model="codeForm.mobile" autocomplete="off" placeholder="手机号"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="codeNum">
                                        <el-input v-model="codeForm.codeNum" autocomplete="off" placeholder="验证码">
                                            <el-button slot="append" style="font-size: 14px">获取验证码</el-button>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="submitForm('codeForm')">登录</el-button>
                                    </el-form-item>
                               </el-form>
                            </el-tab-pane>-->
                        </el-tabs>
                    </div>
                </div>
                <div class="after-right right flex-space-between-columns">
                    <div class="after-rightBox flex-space-around-columns">
                        <h3>智能辅助诊疗系统</h3>
                        <!-- <h3>国医互联</h3> -->
                        <div class="line-box"><div class="line"></div></div>
                        <h4>How is concept?</h4>
                        <h4>专注客户体验<br>创新引领技术</h4>
                    </div>
                    <p class="slogan">专注<span>•</span>一流<span>•</span>协作<span>•</span>创新</p>

                </div>
            </div>
        </div>
        <div class="login-footer-wrap">
            <div class="login-footer">
                <div class="footer-nav">
                    <!-- <p>
                        <router-link to="/index">首页</router-link>
                        <span>-</span>
                        <router-link to="/company/company">公司介绍</router-link>

                        <span>-</span>
                        <router-link to="/company/contactUs">联系我们</router-link>
                        <span>-</span>
                        <a target="_blank" href="/20210712091219.pdf">使用帮助</a>
                        <span>-</span>
                        <router-link to="/company/disclaimer">隐私政策</router-link>
                        <span>-</span>
                        <router-link to="/company/UserAgreement">服务协议</router-link>
                    </p> -->
                </div>
                <div class="copyright">
                    <p class="imgSrc">
                        <span @click="toImg(4)">互联网药品许可证</span><span class="line">|</span>
                        <span @click="toImg(2)">增值电信业务经营许可证</span>
                        <span class="line">|</span>
                        <span @click="toImg(3)">广播电视台节目制作许可证</span>
                        <span class="line">|</span>
                        <a href="http://ccm.mct.gov.cn:80/ccnt/sczr/service/business/emark/toDetail/de3aa6f9d69d4187b1b25e28f2a043c4"
                            target="view_window">
                            <img class="img-bottom" src="../assets/img/imgIcon.jpg" alt=""></img>
                            <span>网络文化经营许可证</span>
                        </a>

                    </p>
                    <p>©2021qyyl365.com版权所有<a style="color: #999999;" href="https://beian.miit.gov.cn/"
                            target="view_window">豫ICP备2021000748号</a>.千越医疗科技有限公司提供技术支持</p>
                </div>
            </div>
            <!-- <div class="qrcode">
                <div class="left">
                    <img width="80" src="../assets/img/qrcode.png" alt="">
                    <p>关注公众号</p>
                </div>
                <div class="left">
                    <img width="80" src="../assets/img/ampCode.jpg" alt="">
                    <p>微信小程序</p>
                </div>
                <div class="left">
                    <img width="80" src="../assets/img/code_d3.png" alt="">
                    <p>APP下载(安卓)</p>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import ftCom from '../components/footer'
import { UserInfo } from '../components/User/UserInfo'
import { getDrugOrHerbal } from '../utils/diseaseUtility'
export default {
    name: 'login',
    components: {
        ftCom
    },
    data() {
        var userInfo = new UserInfo(this.TokenClient, this.Services.Authorization)
        return {
            img_list: [
                require('../assets/img/login_banner1.jpg'),
                require('../assets/img/login_banner1.jpg'),
                require('../assets/img/login_banner1.jpg')
            ],
            activeName: 'first',
            passForm: {
                password: '',
                username: '',
            },
            codeForm: {
                mobile: '',
                codeNum: ''
            },
            isRemember: false,
            Agreement: false,
            userinfoDomain: userInfo,
            detailUserType:this.$store.state.localData.userInfo.detailUserType
            // redirect : this.$route.query.redirect
        }
    },
    created() {
        getDrugOrHerbal('')
    },
    mounted() {
        this.getCookie();
        this.loginAnimation()
    },
    methods: {
        toImg(type) {
            this.$router.push({
                path: '/imgShow',
                query: {
                    type: type
                }

            })
        },
        loginAnimation() {
            $('.before-left-box').hide()
            $('.before-left').addClass('animation');
            $('.before-right').addClass('animation');
            $('.after').addClass('animation');
            setTimeout(function () {
                $('.before').hide()
            }, 1000)
        },
        submitForm: function () {
            if (this.Agreement == false) {
                this.$message.error('请勾选相关《服务协议》和《隐私政策》');
                return false;
            }
            if (this.passForm.username == '' || this.passForm.password == '') {
                this.$message.error('请输入用户名或密码');
                return false;
            }
            if (this.isRemember == true) {
                console.log("checked == true");
                //传入账号名，密码，和保存天数3个参数
                this.setCookie(this.passForm.username, this.passForm.password, this.Agreement, this.isRemember, 7);
            } else {
                console.log("清空Cookie");
                //清空Cookie
                this.clearCookie();
            }
            var _this = this;
            var longinInfo = {
                Username: _this.passForm.username.replace(/(^\s*)|(\s*$)/g, ""),
                Password: _this.passForm.password.replace(/(^\s*)|(\s*$)/g, ""),
                GrantType: 'password'
            }
            _this.userinfoDomain.Login(longinInfo, function (data) {
                // 专家跳转
                if(_this.$store.state.localData.userInfo.detailUserType == '4') {
                    _this.$router.push({
                        path: "/indexExpert",
                        query: {},
                    });
                } else {
                    _this.$router.push({
                        path: "/index",
                        query: {},
                    });
                }

  
            }, 
            function (err) {
                _this.$message.error('账号密码错误，请重新输入！')
            }
            )
        },
        //设置cookie
        setCookie(c_name, c_pwd, c_agree, exdays) {
            var exdate = new Date(); //获取时间
            exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
            //字符串拼接cookie
            window.document.cookie = "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
            window.document.cookie = "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
            window.document.cookie = "agreement" + "=" + c_agree + ";path=/;expires=" + exdate.toGMTString();
            window.document.cookie = "isRemember" + "=" + c_agree + ";path=/;expires=" + exdate.toGMTString();
        },
        //读取cookie
        getCookie: function () {
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    //判断查找相对应的值
                    if (arr2[0] == 'userName') {
                        this.passForm.username = arr2[1]; //保存到保存数据的地方
                    } else if (arr2[0] == 'userPwd') {
                        this.passForm.password = arr2[1];
                    } else if (arr2[0] == 'agreement') {
                        this.Agreement = Boolean(arr2[1]);
                    } else if (arr2[0] == 'isRemember') {
                        this.isRemember = Boolean(arr2[1]);
                    }
                }
            }
        },
        //清除cookie
        clearCookie: function () {
            this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
        },
        validateBtn() {
            //倒计时
            let time = 60;
            let timer = setInterval(() => {
                if (time == 0) {
                    clearInterval(timer);
                    this.disabled = false;
                    this.btnTitle = "获取验证码";
                } else {
                    this.btnTitle = time + '秒后重试';
                    this.disabled = true;
                    time--
                }
            }, 1000)
        },
    }
}
</script>
<style scoped>
.ExpertBox {
    margin: 38px 0;
    margin-bottom: 80px;
}
.ExpertSize {
    font-size: 30px;
    color: #333333;
    margin-top: 10px;
    text-align: center;
}
.login {
    position: fixed;
    width: 100%;
    height: 100%;
    background: url("../assets/img/login_bg.jpg")center no-repeat;
    background-size: cover;
}
.ExpertLogo {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 70px;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0px 8px 12px 0px rgba(139,192,206,0.3);
    border-radius: 16px;
    text-align: center;
}
.loginExpert {
    position: fixed;
    width: 100%;
    height: 100%;
    background: url("../assets/img/loginExpert_bg.png")center no-repeat;
    background-size: cover;
}
.loginExpertBox {
    /* width: 560px; */
    /* height: 640px; */
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* C3F1FD */
    background: linear-gradient(180deg, #e1f6fd, #FFFFFF);
    box-shadow: 0px 8px 12px 0px rgba(131,193,228,0.3);
    border-radius: 40px;
    padding: 0 40px;
    padding-bottom: 70px;
}

.login-wrap {
    position: relative;
    width: 80vw;
    height: 70vh;
    margin: calc((100vh - 70vh)/2) auto 0;
    background: #FFFFFF;
    overflow: hidden;
}

.side-img {
    position: absolute;
    left: -125px;
    top: 50%;
    transform: translateY(-50%);
}

.before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.before>div {
    width: 50%;
    height: 100%;
}

.before-left {
    position: absolute;
    left: 0;
    background: url("../assets/img/before_left_bg.png") center no-repeat;
    background-size: cover;
    align-items: flex-start !important;
}

.before-left.animation {
    z-index: 90;
    transform: translateX(860px);
    transition: all linear .55s;
}

.before-right.animation {
    visibility: hidden;
    position: relative;
    z-index: 80;
    transform: translateX(-100px);
    transition: all linear .25s;
}

.before-left-box {
    width: 85%;
    height: 60%;
}

.before-left-box h3 {
    font-weight: normal;
    /* margin-top: 80px; */
    font-size: 32px;
    color: #FFFFFF;
    text-align: center;
}

.before-left-box .line {
    width: 200px;
    height: 4px;
    background: #FFFFFF;
    margin: 10px auto;
}

.before-left-box h4 {
    /* padding-top: 30px; */
    font-weight: normal;
    font-size: 24px;
    color: #FFFFFF;
    text-align: center;
}

.before-left-box .slogan {
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
}

.before-left-box .slogan span {
    display: inline-block;
    margin: 0 10px;
}

.before-left-box button {
    display: block;
    margin: 10px auto 0;
    width: 146px;
    height: 55px;
    color: #FFFFFF;
    border-radius: 27px;
    border: 2px solid #FFFFFF;
    background: none;
    font-size: 20px;
    cursor: pointer;
}

.before-left-box button:hover {
    background: #FFFFFF;
    color: #04a290;
}

.before-right {
    position: absolute;
    z-index: 80;
    right: 0;
    box-sizing: border-box;
}

.before-right img {
    height: 100%;
}

.after {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 200px;
    z-index: 98;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.after.animation {
    opacity: 1;
    left: 0px;
    z-index: 100;
    transition: all linear .75s;
}

.after>div {
    height: 100%;
}

.login-con {
    position: relative;
    width: 60%;
    background: #FFFFFF;
}

.login-con .login-logo {
    position: absolute;
    top: 50px;
    left: 50px;
}

.login-con .login-from {
    width: 60%;
}

.after-right {
    position: relative;
    width: 40%;
    /* padding: 20px; */
    box-sizing: border-box;
    height: 100%;
    background: url("../assets/img/after_right_bg.jpg")center no-repeat;
    background-size: cover;
    padding-right: 1vw;
    align-items: flex-end !important;
}

.after-rightBox {
    height: 70%;
}

.after-right h3 {
    width: 100%;
    text-align: right;
    font-weight: normal;
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 32px;
    color: #FFFFFF;
    text-align: right;
}

.after-right .line-box {
    text-align: right;
    width: 100%;
    position: relative;
    height: 4px;
}
.after-right .line {
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 4px;
    background: #FFFFFF;
}

.after-right h4 {
    width: 100%;
    text-align: right;
    margin-top: 40px;
    font-weight: normal;
    font-size: 24px;
    color: #FFFFFF;
    text-align: right;
}

.after-right .slogan {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
}

.after-right .slogan span {
    display: inline-block;
    margin: 0 10px;
}

.login-footer-wrap {
    position: fixed;
    width: 1300px;
    /* margin: 200px auto; */
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.login-footer {
    width: 100%;
    float: left;
}

.footer-nav {
    color: #999999;
    text-align: center;
    padding-bottom: 20px;
}

.footer-nav a {
    color: #999999;
    display: inline-block;
    margin: 0 15px;
}

.footer-nav a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.footer-nav span {
    color: #999999;
}

.login-footer .copyright {
    border-top: 1px solid rgba(153, 153, 153, 0.2);
    padding: 25px 0;
    color: #999999;
    text-align: center;
}

.login .qrcode {
    text-align: center;
    float: right;
}

.login .qrcode div {
    margin-left: 15px;
}

.agreement-box {
    margin: 0px auto;
    width: 1200px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    /*border: 1px solid #e5e5e5;*/
}

.agreement-tit {
    padding: 10px 0 20px;
    border-bottom: 1px solid #e5e5e5;
}

.agreement-tit h3 {
    font-size: 20px;
    color: #333333;
    text-align: center;
    font-weight: bold;
}

.agreement-content {
    padding: 25px 0;
}

.agreement-content p {
    text-indent: 28px;
    line-height: 2;
}

.agreement-content p.s-p {
    text-indent: 42px;
}

.agreement-content h4 {
    text-indent: 28px;
    line-height: 1.7;
    padding: 15px 0;
}

.imgSrc span {
    cursor: pointer;
    color: #999999;
}

span.line {
    margin: 0 5px;
}

.img-bottom {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: -8px 5px;
}

.img {
    width: 100%;
    height: 100%;
}

.copyright p {
    line-height: 30px;
    height: 30px;
}

.imgSrc span:hover,
a:hover {
    cursor: pointer;
    color: #1fb7a6 !important;
}
</style>
<style>
.login .el-tabs__item {
    font-size: 18px;
}

.login-wrap .el-button {
    width: 100%;
    font-size: 18px;
    padding: 16px 20px;
}

.login .el-tabs__content {
    padding-top: 35px;
}

.login .el-input__inner {
    background-color: #f4f4f4;
    height: 50px;
    line-height: 50px;
}

.login .el-form-item {
    margin-bottom: 35px;
}

.login .el-checkbox__label a {
    color: #1fb7a6;
}

.login .el-checkbox__label a:hover {
    text-decoration: underline;
}
</style>

<template>
    <div class="healthKnow zhong">
        <hd-com></hd-com>
        <!-- <div class="banner">
            <div class="banner-text">
                <h3>院前急救</h3>
                <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>院前急救</a></p>
            </div>
        </div> -->
        <div class="content-wrap">
            <div class="content clearfix">
                <img class="contentImg" src="../../assets/img/aidImg.png" alt="" srcset="" />
                <div class="contentAid">
                      <el-input placeholder="请输入内容" v-model="keyWord" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="searchAid"></el-button>
                    </el-input>
                    <div class="" style="display: flex; flex-wrap: wrap">
                        <div class="" v-for="(item, index) in categoryData" :key="index" style="margin-top: 10px">
                            <div class='goodTag' @click="Detail(item.id)">
                                {{ item.name }}
                            </div>
                        </div>
                        <div style="margin-top: 10px">
                          <div v-if="pageIndex < pageCount" class="goodTag" @click="gotextAid">更多...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
import { FamilyFirstAid } from "../../components/familyFirstAid/familyFirstAid"
import hdCom from '../../components/header'
import ftCom from '../../components/footer'
import rtBar from "../../components/right";
export default {
    name: "",
    components: {
        hdCom,
        ftCom,
        rtBar
    },
    data() {
        var familyFirstAid = new FamilyFirstAid(this.TokenClient, this.Services.Disease)
        return {
            categoryData: {},
            detailInfo: {}, //初始化详情
            familyFirstAidDomain: familyFirstAid,
            defaultActive: 0,
            analysisRank: [],
            fontSize: "",
            pageIndex:1,
            keyWord:'',
            pageCount:0
        }
    },
    methods: {
        getFontSize(data) {
            this.fontSize = data
        },
        searchAid(){
            this.pageIndex = 1
            this.categoryData = []
            this.Init()
        },
        Init() {
            var _this = this;
            var keyWordsearch = ''
            if (_this.keyWord == '' ||  _this.keyWord == ' ') {
                keyWordsearch = '%20'
            } else {
                keyWordsearch = _this.keyWord
            }
            _this.familyFirstAidDomain.FamilyFirstAidSort(_this.pageIndex,'0', keyWordsearch,function (data) {
                if (_this.pageIndex < data.data.pageCount) {
                    _this.categoryData = data.data.results;
                } else {
                    _this.categoryData = _this.categoryData.concat(  data.data.results)
                }
                _this.pageCount = data.data.pageCount
            },
            function (error) {
                console.log('健康常识请求异常!请刷新重试。', error)
            })
        },
        gotextAid(){
            this.pageIndex = this.pageIndex + 1
            this.Init()
        },
        Detail(hashKey){
            this.$router.push({
                path: "./familyFirstAidDetail",
                query: {
                    id: hashKey,
                },
            });
        },
    },
    mounted() {
        this.Init();
    },
}
</script>

<style scoped>
.input-with-select {
    width: 50%;
}
.banner {
    height: 116px;
    background: url("../../assets/img/jbk_banner_zy.jpg")center no-repeat;
}

.banner-text {
    width: 1200px;
    margin: 0 auto;
}

.banner-text h3 {
    padding-top: 25px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 18px;
    color: #ffffff;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

.content-wrap {
    background: #fcfcfc;
}

.content-top {
    height: 64px;
    background: #FFFFFF;
    box-shadow: 2px 0 5px rgba(102, 102, 102, 0.08);
}

.content {
    position: relative;
    width: 1440px;
    background: none;
    margin: 0 auto;
    padding: 30px 0;
    min-height: 500px;
}
.contentAid{
    max-height: 450px;
    background: #ffffff;
    border-radius: 24px;
    padding: 30px;
    overflow-y: auto;
    margin-top: 20px;
    padding-top: 30px;
}
.goodTag:hover {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #ffe6cd;
  border-radius: 20px;
  margin-left: 10px;
  color: #df7d1f;
  margin-top: 10px;
  cursor: pointer;
}
.goodTag {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.contentImg {
    width: 100%;
    height: 100%;
}
</style>

<template>
  <div>
      <!-- <div v-if="myUserInfo.isPharmacist == 1" class="titleTopCenterLeftBox" @click="goDoctor">处方审核<el-badge :value="waitDataTotalNumber" class="item" /></div> -->
      <div :class="userInfo.isAuditDiagnose == 'True' ? 'titleTopCenterLeftBox' : 'titleTopCenterLeftBoxno'" @click="goDoctor">处方审核<el-badge :value="waitDataTotalNumber" class="item" /></div>
  </div>
</template>
<script>
export default {
  props: {
    waitDataTotalNumber: Number,
  },
  data() {
    return {
      activeName: "0",
      userInfo: this.$store.state.localData.userInfo,
      myUserInfo:JSON.parse(sessionStorage.getItem('userInfo'))
    };
  },
  methods: {
      goDoctor() {
      if (this.userInfo.isAuditDiagnose == "True") {
        this.$router.push({
          name: "PremangePatientList",
        });
      } else {
        this.$message({
          message: "暂无审方权限,请联系管理员添加",
          type: "warning",
        });
      }
      // this.doctorDialogVisible = true
      // this.GetAuditDoctorData()
    },
  }
};
</script>
<style scoped>
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  /* margin: 30px auto; */
    margin: 0px auto;
  margin-top: 30px;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
   margin: 0px auto;
  margin-top: 30px;
  cursor: pointer;
  /* margin-top: 0; */
}

</style>

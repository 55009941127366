<template>
  <div id="com-header">
    <div class="header">
      <div class="head-con clearfix">
        <div class="head-left left clearfix">
          <div class="logo1 left">
            <img v-if="orgimgkeyPathData2" :src=" orgimgkeyPathData2.orgimgkeyPath2" alt="" />
            <img v-else src="../assets/img/logo.png" alt="" />
          </div>
          <div class="navbar left">
            <ul>
              <li v-for="(item, index) in menuList" :key="index">
                <template v-if="item.url == '/'">
                  <template v-if="item.name == '诊断' || item.name == '药品库'">
                    <a href="javascript:void(0);">{{ item.name }}<i class="el-icon-caret-bottom"></i></a>
                  </template>
                  <template v-else>
                    <a href="javascript:void(0);">{{ item.name }}</a>
                  </template>
                  <dl class="sub-nav">
                    <dt v-for="(subItem, i) in item.children" :key="i">
                      <router-link v-if="subItem.url != undefined" :to="subItem.url">{{ subItem.name }}</router-link>
                    </dt>
                  </dl>
                </template>
                <template v-else>
                  <router-link v-if="item.url != undefined" :to="item.url">{{
                    item.name
                  }}</router-link>
                </template>
              </li>
            </ul>
          </div>
        </div>
        <div class="head-right right clearfix">
          <div class="search left">
            <el-form :model="searchFrom">
              <el-select v-model="searchFrom.headSelected" placeholder="请选择">
                <el-option v-for="item in searchFrom.selectList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-input v-model="searchFrom.keywords" placeholder="关键字查询..." style="width: 200px" clearable
                @keydown.enter.native="SearchInputKeydown"></el-input>
              <button class="search-btn" type="button" @click="SearchInputKeydown()"></button>
            </el-form>
          </div>
          <div style="display:flex; align-items: center;">
            <div class="mesTopBox" style="display:flex;align-items: center;cursor: pointer;margin-left:30px;position: relative;" @click="gomessIndex">
              <i class="el-icon-bell" style="font-size:20px"></i>
              <p style="margin-left:5px">消息通知</p>
              <div class="mesTopItem" v-if="IsHomeMessage">
              </div>
            </div>
          <div class="personalCenter right">
            <div v-if="!userId">
              <p class="login-txt">
                <router-link to="/login">登录</router-link>
              </p>
            </div>
            <div v-else>
              <p class="login-txt">
                <i class="iconfont" style="font-size: 18px; vertical-align: middle">&#xe608;</i>个人中心
              </p>
              <div class="per-hover">
                <div class="head-box">
                  <div class="head-img">
                    <img v-if="userhead == '' || userhead == null" src="../assets/img/head_img.png" alt="" />
                    <img v-else :src="Services.ossUpload +  userhead" alt="" />
                  </div>
                  <p>{{ userName }}<span v-if="roleId == 3">医生</span></p>
                </div>
                <ul class="per-info clearfix">
                  <li>
                    <router-link to="/personal/personalMoney">
                      <div class="s-icon s-icon3"><i class="el-icon-s-finance"></i></div>
                      <p>账单管理</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/interrogation/referralAndmedical">
                      <div class="s-icon s-icon1"></div>
                      <p>转诊管理</p>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/personal/SelfTest">
                      <div class="s-icon s-icon2"></div>
                      <p>测试记录</p>
                    </router-link>
                  </li>
                </ul>
                <div class="status">
                  <span><router-link to="/personal/index" style="color: #1fb7a6">个人中心</router-link></span>
                  <span><a href="javascript:void(0);" @click="out">退出登录</a></span>
                </div>
              </div>
            </div>
          </div>
          </div>
 

        </div>
      </div>
    </div>
    <!-- <touch></touch> -->
  </div>
</template>

<script>
import touch from "../components/touch";
import { search } from "../utils/relation";
import storage from "../utils/customstorage";
import { loginOut } from "../utils/userInfo";
import { Menus } from "./Menu/Menu";
import { MessageDomain } from '../components/personal/Message';
export default {
  name: "headerNav",
  inject: ["reload"],
  components: {
        touch
    },
  data() {
    var menu = new Menus(this.TokenClient, this.Services.Authorization);
    var MessageDomainTemp = new MessageDomain(this.TokenClient, this.Services.Authorization);
    var searchOptions = menu.GetSearchModules();
    return {
      menuDomain: menu,
      menuList: [],
      searchFrom: {
        selectList: searchOptions,
        headSelected: "all",
        keywords: "",
      },
      userId: this.$store.state.localData.userInfo.id,
      userName: this.$store.state.localData.userInfo.name,
      userhead: this.$store.state.localData.userInfo.photo,
      roleId: this.$store.state.localData.userInfo.roleId,
      categoryId: this.$store.state.localData.userInfo.categoryId,
      orgimgkeyPathData2:JSON.parse(localStorage.getItem('orgimgData2')) ? JSON.parse(localStorage.getItem('orgimgData2')) : '',
      IsHomeMessage: this.$store.state.isRead,
      Result: MessageDomainTemp,
    };
  },
  watch: {
      IsHomeMessage(newVal, oldVal) {
        console.log(newVal, oldVal)
      }
  },
  created() {
    if (this.$route.query.searchType != null)
      this.searchFrom.headSelected = this.$route.query.searchType;
    if (this.$route.query.keyword != null)
      this.searchFrom.keywords = this.$route.query.keyword;
    this.getHomeMessageData()
    this.getMenus();
    this.$bus.$off("select");
    this.$bus.$on("select", (msg) => {
      this.searchFrom.headSelected = msg;
    });
  },
  methods: {
      getHomeMessageData() {
          let _this = this;
          _this.Result.GetHomeMessage(function (data) {
              let readNumber = 0
              for (let i=0; i<data.data.length; i++) {
              readNumber = readNumber + data.data[i].unreadCount
              }
              if (readNumber > 0) {
              _this.$store.commit('messageData', true)
              } else {
              _this.$store.commit('messageData', false)
              }
          }, function (error) {
              console.log(error);
          })
    },
    gomessIndex(){
      this.$router.push({
        name:'messageIndex',
        query: {
            typeCom:1
        }
      })
    },
    onSwipeLeft() {
      history.go(-1);
    },
    onSwipeRight() {
      history.go(+1);
    },
    getMenus() {
      var _this = this;
      _this.menuDomain.GetMenu(
        _this.roleId,
        function (data) {
          let keys = Object.keys(data.data)[0];
          _this.menuList = data.data[keys];
        },
        function (err) {
          console.log(err);
        }
      );
    },
    submitSearch: function () {
      console.log(this.searchFrom);
      search(
        this.searchFrom.headSelected,
        this.searchFrom.keywords,
        this.searchFrom.selectList[1].name
      );
    },
    SearchInputKeydown(event) {
      search(
        this.searchFrom.headSelected,
        this.searchFrom.keywords,
        this.searchFrom.selectList[1].name
      );
    },
    out() {
      loginOut();
      this.$message.success("操作成功");
      location.reload()
    },
  },
  mounted() {
    // this.$bus.$off("select");
    // this.$bus.$on("select", (msg) => {
    //   this.searchFrom.headSelected = msg;
    //   console.log("this.searchFrom.headSelected",this.searchFrom.headSelected)
    // });
  },
};
</script>

<style scoped>
@import "../assets/css/header.css";

.search {
  margin-top: 20px;
  padding-left: 10px;
  height: 40px;
  line-height: 38px;
  border: 1px solid #1b9183;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
.logo1 {
  /* width: auto;
  height: 40px; */
  margin-right: 20px;
}
.logo1 img {
  width: auto;
  height: 40px;
  margin-top: 18px;
}
.search input {
  width: 200px;
  padding: 0 5px;
}

.search .search-btn {
  width: 55px;
  height: 38px;
  background: #1b9183 url("../assets/img/search.png") center no-repeat;
  vertical-align: top;
}

.zhong .search {
  border: 1px solid #b76c12;
}

.zhong .search .search-btn {
  background: #b76c12 url("../assets/img/search.png") center no-repeat;
}
</style>
<style>
#com-header .head-con {
  width: 90%;
}
#com-header .el-select {
  width: 95px;
}
#com-header .personalCenter  {
      margin: 0 0px 0 10px
}
#com-header .el-input__inner {
  border: none;
  padding: 0 5px;
}
.mesTopItem {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #EB1E1E;
  position: absolute;
  left: 12px;
  top: 2px;
}
</style>


<template>
  <div class="Message xi">
    <nh-com></nh-com>
    <div class="banner">
      <div class="banner-wrap clearfix">
        <div class="banner-text">
          <h3>个人中心</h3>
          <p>当前位置： <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>账单明细</a></p>
        </div>
      </div>
    </div>
    <div class="personal-content">
      <div class="personal-box clearfix">
        <div class="personal-left">
          <hd-img></hd-img>
        </div>
        <div class="personal-right">
          <div class="personal-right-tit">
            <i class="iconfont">&#xe62c;<span>账单明细</span></i>
          </div>
          <!--    内容    -->
          <div class="centercontentMoney">
            <div class="centercontentMoney1">
              <div class="el-icon-s-finance centercontentMoney1i"></div>
              <div style="display: flex; align-items: baseline">
                <div style="margin-right: 10px">当前余额</div>
                <div>
                  ￥<span class="centercontentMoney1Money">{{ UserBalance.assetsBalance }}</span
                  >元
                </div>
              </div>
              <div style="color: #00afb5" @click="goAppCharge">充值</div>
            </div>
            <div class="centercontentMoney2" @click="goApp">
              <div style="color: #00afb5">提现|转账</div>
              <div>PS：更多提现、转账功能请在手机端操作</div>
            </div>
          </div>
          <div class="recordtitle">
            <el-date-picker v-model="valueDate" type="month" placeholder="选择月" value-format="yyyy-MM" @change="valueDatechange"> </el-date-picker>
            <!-- <el-select v-model="state" placeholder="请选择" size="small" style="margin-right: 10px">
              <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select> -->
            <div class="" style="display: flex">
              <div :class="type == 0 ? 'boxtagCheck' : 'boxtag'" @click="slecttype(0)">全部</div>
              <div :class="type == 1 ? 'boxtagCheck' : 'boxtag'" @click="slecttype(1)" style="margin-left: 10px">支出</div>
              <div :class="type == 2 ? 'boxtagCheck' : 'boxtag'" @click="slecttype(2)" style="margin-left: 10px">收入</div>
            </div>
          </div>
          <div class="center-content">
            <el-table :data="UserBalanceTable">
              <el-table-column prop="addressee" label="时间"
                >pageIndex
                <template slot-scope="scope">
                  {{ scope.row.createTime | timefilters }}
                </template>
              </el-table-column>
              <el-table-column prop="purpose" label="操作" align="center">
                <template slot-scope="scope">
                  <!-- 支出 -->
                  <el-tag size="small" v-if="scope.row.purpose == 'withdrawal'">提现</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'refund'">退款</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'buyMember'">购买会员</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'upgradeMember'">升级会员</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'renewalUpgrade'">续费升级订单</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'renewalMember'">续费会员</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'payVideo'">购买视频</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'teamPerformanceIncome'">团队绩效发放</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'MZRefund'">门诊退款</el-tag>
                  <!-- 收入 -->
                  <el-tag size="small" v-if="scope.row.purpose == 'teamPerformanceDistribution'">团队绩效发放</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'recharge'">充值</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'entryGoods'">商品入账</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'distribution'">分销获利</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'shareMoney'">分享获利</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'shareMoneySecond'">分享获利抽佣</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'publicShare'">公益分红</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'purchaseGoods'">购买商品</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'refundChargebackMoney'">退款手续费</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'saleVideo'">出售视频</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'MZPayment'">门诊付款</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'MZAudit'">门诊审方</el-tag>
                  <el-tag size="small" v-if="scope.row.purpose == 'MZDistribution'">门诊抽佣</el-tag>

                </template>
              </el-table-column>
              <el-table-column prop="paymentMethod" label="支付方式" align="center">
                <template slot-scope="scope">
                  <el-tag size="small" type="success" v-if="scope.row.paymentMethod == 0 && scope.row.purpose != 'recharge' && scope.row.state == 1 && scope.row.purpose != 'publicShare' && scope.row.purpose != 'teamShare'">余额支付</el-tag>
                  <el-tag size="small" type="success" v-if="scope.row.paymentMethod == 1 && scope.row.state == 1">微信支付</el-tag>
                  <el-tag size="small" type="success" v-if="scope.row.paymentMethod == 2 && scope.row.state == 1">支付宝支付</el-tag>
                  <el-tag size="small" type="warning" v-if="scope.row.state == 0">待入账</el-tag>
                  <el-tag size="small" type="danger" v-if="scope.row.state == -1">已拒绝</el-tag>
                  <el-tag size="small" type="info" v-if="scope.row.state == -2">已取消</el-tag>
                  <el-tag size="small" type="danger" v-if="scope.row.state == -3">交易取消</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="余额" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.type == 2 && scope.row.state == 1">{{ "+ " + scope.row.amount }}</div>
                  <div v-if="scope.row.type == 1 && scope.row.state == 1">{{ "- " + scope.row.amount }}</div>
                  <div v-if="scope.row.state == -1 || scope.row.state == -2 || scope.row.state == 0 || scope.row.state == -3" style="color: #666">{{ scope.row.amount }}</div>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination background layout="prev, pager, next" @current-change="AuditCurrentChange" :current-page.sync="AuditcurrentPage" :total="auditTotal"> </el-pagination>
            </div>
          </div>
          <!--    详情    -->
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="appdialogVisible" width="30%">
      <div class="imgBox">
        <div class="imgBoxurl">
          <el-image class="imgBoxurl1" :src="url1" alt="" srcset="" />
          <div>微信扫码打开小程序</div>
        </div>
        <div class="imgBoxurl">
          <el-image class="imgBoxurl1" :src="url2" alt="" srcset="" />
          <div>浏览器扫码下载APP</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="appdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="appdialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="余额充值" :visible.sync="chargedialogVisible" width="30%">
      <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="充值金额" prop="money">
          <el-input v-model="ruleForm.money"></el-input>
        </el-form-item>
        <el-form-item label="支付方式" prop="radio">
          <el-radio label="1" v-model="ruleForm.radio">微信</el-radio>
        </el-form-item>
      </el-form>
      <div class="imgBoxurl" v-if="qRcodeUrl">
        <el-image class="imgBoxurl1" :src="qRcodeUrl" alt="Base64 Image" srcset=""  />
        <div>打开微信扫码支付</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chargedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="gochargedialogVisible">确 定</el-button>
      </span>
    </el-dialog>
    <ft-com></ft-com>
  </div>
</template>

<script>
import { shoppingMall } from "../../components/shoppingMall/shoppingMall";
// import { shoppingMall } from '../../components/shoppingMall/shoppingMall';
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
var pageIndex = 1;
var keywordVal = "";
var myDate = new Date();
export default {
  name: "Message",
  components: {
    ftCom,
    nhCom,
    hdImg,
  },
  data() {
    var shoppingMalltem = new shoppingMall(this.TokenClient, this.Services.shoppingMall);
    var UserDistributorltem = new shoppingMall(this.TokenClient, this.Services.Authorization);
    var UserDistributorltemdrug = new shoppingMall(this.TokenClient, this.Services.Drug);

    return {
      UserBalance: {
        assetsBalance: 0,
      },
      labelPosition: "right",
      MessageList: [],
      MessageDetail: {},
      total: 0,
      currentPage: 1, //初始页
      keyword: "",
      PageTotal: 1,
      MessageTotal: 0,
      rloading: [], //loading遮罩
      shoppingMallMain: shoppingMalltem,
      UserDistributorMain: UserDistributorltem,
      UserDistributorMaindrug: UserDistributorltemdrug,
      Image: {
        //单击图片上传控件中的图片时，放大显示
        dialogVisible: false,
        dialogImageUrl: "",
      },
      dialogVisible: false,
      RegionData: [],
      UserBalanceTable: [],
      today: myDate.getFullYear(),
      month: myDate.getMonth() + 1,
      type: "0",
      pageIndex: 1,
      valueDate: "",
      auditTotal: 0,
      AuditcurrentPage: 0,
      appdialogVisible: false,
      chargedialogVisible: false,
      url1: require("@/assets/img/interrogation/xiaoimg.png"),
      url2: require("@/assets/img/interrogation/APPimg.png"),
      ruleForm: {
        money: "",
        radio: "1",
      },
      userInfo: this.$store.state.localData.userInfo,
      weChatInfo: "",
      qRcodeUrl: "",
      timer: null,
      statusOption: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "2",
          label: "余额",
        },
        {
          value: "1",
          label: "微信",
        },
      ],
    };
  },
  mounted() {
    this.AllMessages();
    this.zero();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    gochargedialogVisible() {
      this.wxPayAgent();
    },
    wxPayAgent() {
      var _this = this;
      var parmas = {
        amount: _this.ruleForm.money,
        id: _this.userInfo.id,
        orderNum: "",
        type: 1,
        attach: "recharge",
        RechargeType: 2,
      };
      _this.UserDistributorMain.Recharge(
        parmas,
        function (data) {
          // _this.qRcodeUrl = _this.Services.Authorization + data.data.qRcodeUrl;
        _this.qRcodeUrl = 'data:image/jpeg;base64,' + data.data.qRcodeBase64;

          _this.weChatInfo = data.data;
          var isSuccess = false;
          _this.timer = window.setInterval(() => {
            setTimeout(function () {
              _this.UserDistributorMain.WeChatPayVerificationSuccessPay(
                _this.weChatInfo,
                function (data2) {
                  isSuccess = data2.data.isSuccess;
                  if (isSuccess) {
                    clearInterval(_this.timer);
                    _this.$message({
                      type: "success",
                      message: "支付成功!",
                    });
                    _this.chargedialogVisible = false;
                    _this.AllMessages();
                    _this.getAssetsDetailsList();
                  }
                },
                function (error) {
                  console.log(error);
                }
              );
            }, 0);
          }, 5000);
          setTimeout(() => {
            if (!isSuccess) {
              clearInterval(timer);
              if (paymentDialog.value) {
                proxy.$message({
                  type: "error",
                  message: "支付超时!",
                });
                getList();
              }
            }
          }, 300000);
        },
        function (error) {
          console.log(error);
        }
      );
    },
    AuditCurrentChange(pageNum) {
      this.AuditcurrentPage = pageNum;
      this.pageIndex = pageNum;
      this.getAssetsDetailsListime(this.valueDate);
    },
    slecttype(e) {
      this.MessageDetail = [];
      this.pageIndex = 1;
      this.AuditcurrentPage = 0
      if (e == 0) {
        this.type = 0;
        this.getAssetsDetailsListime(this.valueDate);
      } else if (e == 1) {
        this.type = 1;
        this.getAssetsDetailsListime(this.valueDate);
      } else {
        this.type = 2;
        this.getAssetsDetailsListime(this.valueDate);
      }
    },
    gorecord(item) {
      var _this = this;
      _this.dialogVisible = true;
      _this.ruleForm = item;
    },
    valueDatechange(e) {
      this.getAssetsDetailsListime(e);
    },
    //返回列表页
    goBack() {
      $(".center-content").show();
      $(".personal-right-tit").show();
      $(".msg-detail").hide();
    },
    //分页点击事件
    handleCurrentChange: function (pageNum) {
      this.currentPage = pageNum;
      pageIndex = pageNum;
      this.getAssetsDetailsListime(this.valueDate);
    },
    AllMessages() {
      var _this = this;
      _this.UserDistributorMain.getUserDistributor(
        function (data) {
          _this.UserBalance = data.data;
        },
        function (err) {
          _this.UserBalance = [];
          console.log(err);
        }
      );
    },
    zero() {
      if (parseInt(this.month) < 10) {
        this.month = "0" + this.month;
      }
      this.valueDate = this.today + "-" + this.month;
      this.getAssetsDetailsList();
    },
    getAssetsDetailsListime(todate) {
      var _this = this;
      _this.UserDistributorMain.getAssetsDetails(
        "0",
        todate,
        _this.type.toString(),
        _this.pageIndex,
        '10',
        function (data) {
          _this.UserBalanceTable = data.data.results;
          _this.auditTotal = data.data.dataTotal;
        },
        function (err) {
          _this.UserBalanceTable = [];
          console.log(err);
        }
      );
    },
    getAssetsDetailsList() {
      var _this = this;
      let todate = _this.today + "-" + _this.month;
      _this.UserDistributorMain.getAssetsDetails(
        "0",
        todate,
        _this.type.toString(),
        _this.pageIndex,
        '10',
        function (data) {
          _this.UserBalanceTable = data.data.results;
          _this.auditTotal = data.data.dataTotal;
        },
        function (err) {
          _this.UserBalanceTable = [];
          console.log(err);
        }
      );
    },
    goApp() {
      this.appdialogVisible = true;
    },
    goAppCharge() {
      this.chargedialogVisible = true;
      this.ruleForm = {
        money: "",
        radio: "1",
      };
      this.qRcodeUrl = "";
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/footer.css";

#page {
  text-align: center;
  padding: 100px 0 30px;
}
.imgBox {
  display: flex;
  justify-content: space-between;
  margin: 0 60px;
}
.imgBoxurl {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imgBoxurl1 {
  width: 180px;
  height: 180px;
}
.pageBox {
  text-align: center;
  margin-top: 5px;
}
.recordtitle {
  display: flex;
  justify-content: space-between;
  padding: 25px 30px;
  align-items: center;
  padding-bottom: 0;
}
.boxtagCheck {
  padding: 10px 20px;
  background-color: #00afb5;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.boxtag {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  font-size: 14px;
}
.banner {
  width: 100%;
  height: 160px;
  background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.banner-wrap {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.banner-text {
  float: left;
}

.banner-text h3 {
  padding-top: 35px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 14px;
  color: #ffffff;
  margin-top: 20px;
}
.centercontentMoney {
  margin: 20px;
  background: #f5f5f5;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.centercontentMoney1 {
  width: calc(100% - 300px);
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}
.centercontentMoney2 {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.centercontentMoney1i {
  font-size: 42px;
}
.centercontentMoney1Money {
  font-size: 48px;
  color: #00afb5;
}
.center-content {
  margin: 10px;
}
.banner-text p a {
  color: #ffffff;
  font-size: 14px;
  margin: 0 5px;
}

/**/
.personal-content {
  background: #f7f7f7;
  padding: 40px 0;
}

.personal-box {
  width: 1200px;
  margin: 0 auto;
}

.personal-left {
  float: left;
}

.personal-right {
  float: right;
  width: 900px;
  min-height: 740px;
  background: #ffffff;
  box-sizing: border-box;
}

.personal-right-tit {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.personal-right-tit .iconfont {
  color: #04a290;
  font-size: 24px;
  margin-right: 5px;
}

.personal-right-tit span {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  color: #333333;
}

.personal-right-tit span em {
  font-style: normal;
  color: #04a290;
}

.personal-right-tit .el-icon-delete {
  float: right;
  font-size: 18px;
}

.msg-list {
  padding: 0 20px;
}

.msg-list .msg-item {
  padding: 8px 0;
  border-bottom: 1px dashed #e5e5e5;
  line-height: 40px;
  height: 40px;
}

.msg-list .msg-item h4 {
  color: #999999;
  font-weight: normal;
  width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.msg-list .msg-item.noread h4 {
  color: #333333;
}

.msg-list .msg-item p {
  color: #999999;
}

.msg-detail {
  padding: 20px;
  display: none;
}

.msg-detail .mess-tit {
  text-align: center;
}

.msg-detail .mess-info p {
  text-indent: 28px;
}
.mess-info .item-img {
  margin-right: 10px;
  display: flex;
  justify-content: flex-start;
  justify-items: center;
  flex-wrap: wrap;
}
.item-img img {
  width: 118px;
  height: 118px;
}
</style>

<template>
    <div class="resetPassword xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a></p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span>复诊<em>列表</em></span>
                    </div>
                    <!-- 患者列表 -->
                    <!-- <div class="patientBox">
                        <div class="patient" v-for="(item,index) in patientList.patientDiagnose" :key="index">
                            <div class="pList">
                                <div class="pListLeft">
                                    <div style="width: 70px">{{patientList.name}}</div>
                                    <div style="margin-left: 10px">{{patientList.phone}}</div>
                                    <i v-if="patientList.sex == true" class="el-icon-male" style="color:#15C6FF;margin-left: 10px"></i>
                                    <i v-if="patientList.sex == false" class="el-icon-female" style="color:#FF75D1;margin-left: 10px"></i>
                                    <div style="margin-left: 42px">{{patientList.age}}</div>
                                    <div style="margin-left: 50px">{{item.main}}</div>
                                </div>
                                <div>
                                    <el-dropdown>
                                        <span class="el-dropdown-link">
                                            <i class="el-icon-more" style="color:#31bdad"></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item @click="addrecord">查看记录</el-dropdown-item>
                                            <el-dropdown-item @click="addrecord">开具处方</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                            <div style="margin-left: 40px">{{item.addTime}}</div>
                        </div>
                    </div> -->
        <div class="center-contents">
            <div class="iq-card-bodys">
                <el-descriptions class="margin-top" :column="3" border>
                    <el-descriptions-item label="患者姓名">{{patientList.name}}</el-descriptions-item>
                    <el-descriptions-item label="年龄">{{patientList.age}}岁</el-descriptions-item>
                    <el-descriptions-item label="手机号">{{patientList.phone}}</el-descriptions-item>
                    <el-descriptions-item label="联系地址">{{patientList.region != null ? patientList.region.fullName : '-'}}</el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="iq-card-bodys record-box">
                <el-row :gutter="30" class="height1">
                    <el-col :span="8" class="height1">
                        <div class="recordNum">当前患者有 <span class="red">{{diagnoseList.length}}</span> 条就诊记录</div>
                        <el-scrollbar class="left-nav-wrap" style="height: calc(100% - 50px);">
                        <ul class="recordList">
                            <li class="record-item" @click="getcur(item.id)" :class="cursub== item.id ? 'active':''" v-for="(item,index) in diagnoseList" :key="index">
                                <div class="clearfix">
                                    <span class="left record-date">{{item.addTime}}</span>
                                    <span class="right record-name">{{item.diseaseDiagnose}}</span>
                                </div>
                                <p class="record-info">主诉：{{item.main}}</p>
                                <p class="prescribe-btn">
                                    <el-button size="mini" type="success" @click="toPrescribe(item)" plain>开具处方</el-button></p>
                            </li>
                        </ul>
                        </el-scrollbar>
                    </el-col>
                    <el-col :span="16" class="height1">
                        <el-tabs v-model="activeName" type="card" class="tabs-box" @tab-click="tabClick(DiagnoseDetial)">
                            <el-tab-pane label="复诊信息" name="first">
                                <div class="record-mes height1">
                                    <el-scrollbar class="left-nav-wrap" style="height: calc(100% - 10px);">
                                        <el-form label-width="100px">
                                            <el-form-item label="主诉：">
                                                <p>{{DiagnoseDetial.main}}</p>
                                            </el-form-item>
                                            <el-form-item label="现病史：">
                                                <p v-if="DiagnoseDetial.current">{{DiagnoseDetial.current}}</p>
                                                <p v-else>(空)</p>
                                            </el-form-item>
                                            <el-form-item label="既往史：">
                                                <p v-if="DiagnoseDetial.past">{{DiagnoseDetial.past}}</p>
                                                <p v-else>(空)</p>
                                            </el-form-item>
                                            <el-form-item label="过敏史：">
                                                <p v-if="DiagnoseDetial.irritability">{{DiagnoseDetial.irritability}}</p>
                                                <p v-else>(空)</p>
                                            </el-form-item>
                                            <el-form-item label="家族史：">
                                                <p v-if="DiagnoseDetial.family">{{DiagnoseDetial.family}}</p>
                                                <p v-else>(空)</p>
                                            </el-form-item>
                                            <el-form-item label="疾病诊断：">
                                                <el-input type="textarea" :value="DiagnoseDetial.diseaseDiagnose" disabled></el-input>
                                            </el-form-item>
                                            <el-form-item label="症状照片：">
                                                <div>
                                                    <el-image
                                                            v-for="item in DiagnoseDetial.diagnoseImgList" :key='item.id'
                                                            style="width: 100px; height: 100px;margin-right: 15px;"
                                                            :src="http + item.filePath"
                                                            :preview-src-list='[http + item.filePath]'
                                                            fit="fill"></el-image>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="上传处方：">
                                                <div>
                                                    <el-image
                                                            v-for="item in DiagnoseDetial.prescriptionImgList" :key='item.id'
                                                            style="width: 100px; height: 100px;margin-right: 15px;"
                                                            :src="http + item.filePath"
                                                            :preview-src-list='[http + item.filePath]'
                                                            fit="fill"></el-image>
                                                </div>
                                            </el-form-item>
                                        </el-form>
                                    </el-scrollbar>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="处方信息" name="second">
                                <div class="record-mes height1">
                                    <el-scrollbar class="left-nav-wrap" style="height: calc(100% - 10px);">
                                        <el-empty v-if="RXData.length == 0" :image-size="200"></el-empty>
                                        <el-collapse v-else>
                                        <el-collapse-item v-for="(item,index) in RXData" :key="index">
                                            <template slot="title">
                                                <p>
                                                    <template>
                                                        <el-tag v-if="item.auditStatus == -1" type="danger" size="mini">驳回</el-tag>
                                                        <el-tag v-if="item.auditStatus == 0" type="warning" size="mini">待审核</el-tag>
                                                        <el-tag v-if="item.auditStatus == 1" type="success" size="mini">审核通过</el-tag>
                                                        <el-tag v-if="item.auditStatus == 2" type="primary" size="mini">待取药</el-tag>
                                                        <el-tag v-if="item.auditStatus == 3" type="info" size="mini">已完成</el-tag>
                                                    </template>
                                                    处方名称：{{item.prescriptionName}}</p>
                                                <p class="dataTime">{{item.addTime | timefilters}}</p>
                                            </template>
                                            <el-table :data="item.patientPrescriptionList">
                                                <el-table-column prop="compositionName" label="成分" width="180">
                                                    <template slot-scope="scope">
                                                        <div v-html="scope.row.patientComposition.compositionName"></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="dosage" label="用量" width="180">
                                                </el-table-column>
                                                <el-table-column prop="remark" label="炮制方法">
                                                </el-table-column>
                                            </el-table>
                                        </el-collapse-item>
                                    </el-collapse>
                                    </el-scrollbar>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
            </div>
        </div>
                </div>
            </div>
     
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
    import ftCom from '../../components/footer'
    import nhCom from "../../components/navheader";
    import hdImg from "../../components/headerImg";
    import {patientMain} from "../../components/patienTmanagement/patienTmanagement";
    import { Drug } from '../../components/Drug/Drug'
    import { Region } from "../../components/region/Region";
    export default {
        name: "recordList",
        computed: {
            headers() {
                return {
                    "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem('Token')).access_token
                }
            }
        },
        components: {
            ftCom,
            nhCom,
            hdImg
        },
        data() {
            var patient= new patientMain(this.TokenClient, this.Services.Authorization)
            var drug= new Drug(this.TokenClient, this.Services.Drug)
            var region = new Region(this.TokenClient, this.Services.Authorization);
            return {
                patientDomain:patient,
                DrugDomain:drug,
                Region:region,
                labelPosition: 'right',
                resetPassword: {
                    oldPwd:'',
                    newPwd:'',
                    confirmPwd:''
                },
                uploader:null,
                file:'',
                patientType:1,// 固定值
				organitionId: 0,// 固定值
                keyWord:' ',
                pageIndex:1,
                userInfo:{},
                patientList:[],
                visible:false,
                DiagnoseDetial:{},
                cursub: -1,
                RXData:[],
                diagnoseList:[],
                http: this.Services.ossUpload,
                activeName: 'first',
            };
        },
        mounted(){
            this.getList()
        },
        methods: {
            tabClick(item){
                var _this = this;
                console.log(_this.activeName)
                if(_this.activeName == "first"){
                    this.getcur(item.id)
                }else if(_this.activeName == "second"){
                    this.RXData = []
                    this.getRX(item)
                }

            },
            toPrescribe(item){
                this.$router.push({
                    path: '/patienTmanagement/addprescript',
                    query:{
                        patientId: item.patientId,
                        patientDiagnoseId: item.id
                    }
                })
            },
            getcur(i){
                var _this = this;
                this.cursub = i
                _this.activeName = "first";
                _this.patientDomain.GetPatientDiagnose(i,
                    function (data) {
                        _this.DiagnoseDetial = data.data;
                    },
                    function (err) {

                    });

            },
            getRX(i){
                var _this = this;
                _this.DrugDomain.GetDetail(_this.$route.query.patientId,i.id,-999,
                    function (data) {
                        _this.RXData = data.data
                    },
                    function (err) {
                        _this.RXData = [];
                        console.log(_this.RXData)
                    });
            },
            getList () {
                var _this = this;
                _this.patientDomain.GetPatientDetail(_this.$route.query.patientId,function (data) {
                    _this.patientList = data.data
                    _this.Region.getRegions(_this.patientList.id,function(data){
                        _this.patientList.region = data.data
                    })
                    for (var i=0; i<_this.patientList.patientDiagnose.length; i++) {
                        var f = _this.patientList.patientDiagnose[i].addTime.split('T')
                        _this.patientList.patientDiagnose[i].addTime = f[0] + ' ' + f[1].slice(0,8)
                    }
                    _this.diagnoseList = data.data.patientDiagnose
                    _this.cursub = _this.diagnoseList[0].id;
                    _this.getcur(_this.cursub)
                },function (error) {
                    // _this.$message.error('修改失败！旧密码不正确，请重新输入。');
                })
            }
        }
    }
</script>

<style scoped>
    @import "../../assets/css/footer.css";

    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg") center no-repeat;
    }
    .record-box{
        height: calc(100% - 135px);
    }
        .recordNum{
        padding: 0 10px;
        background-color: #fafafa;
        border: 1px solid #EBEEF5;
        height: 36px;
        line-height: 36px;
        border-radius: 4px;
        margin-bottom: 20px;
    }
        .record-info{
        margin-right: 100px;
        margin-top: 15px;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
    }
    .record-item:hover{
        border: 1px solid #5c83f2;
    }
    .record-item.active{
        border: 1px solid #5c83f2;
        background-color: #5c83f2;
        color: #ffffff;
    }
    .record-item.active .record-name{
        color: yellow;
    }
    .record-item .prescribe-btn{
        position: absolute;
        right: 10px;
        top: 50px;
    }
    .record-item .prescribe-btn:hover{
        text-decoration: underline;
    }
    .iq-card-bodys {
        padding: 15px;
        border-radius: 15px;
        background: #FFFFFF;
        margin-bottom: 20px;
    }
        .record-item{
        position: relative;
        border-radius: 4px;
        border: 1px solid #EBEEF5;
        padding: 12px 10px;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .patientheard{
        display: flex;
        align-items: center;
    }
    .patientBox {
        padding: 21px 42px;
        color: #808080
    }
    .patient {
        padding: 21px 0  15px 0;
        border-bottom: 1px dashed #CCCCCC;
    }
    .pList{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .pListLeft {
           display: flex;
        align-items: center;
    }
    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
    /**/
    .personal-content{
        background: #f7f7f7;
        padding: 40px 0;
    }
    .personal-box{
        width: 1200px;
        margin: 0 auto;
    }
    .personal-left{
        float: left;
    }
    .personal-right{
        float: right;
        width: 900px;
        background: #FFFFFF;
        box-sizing: border-box;
    }
    .personal-right-tit{
        border-bottom: 1px solid #e5e5e5;
        padding: 20px;
    }
    .personal-right-tit .iconfont{
        color: #04a290;
        font-size: 24px;
        margin-right: 5px;
    }
    .personal-right-tit span{
        display: inline-block;
        font-size: 24px;
        font-weight: 700;
        color: #333333;
    }
    .personal-right-tit span em{
        font-style: normal;
        color: #04a290;
    }
    .personal-right>form{
        padding: 30px 15px;
        width: 460px;
    }
</style>
<style>
    .personal-center{
        display: none;
    }
</style>

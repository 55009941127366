<template>
    <div class="disclaimer xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-text">
                <h3>隐私政策</h3>
                <p>YIN SI ZHENG CE</p>
            </div>
        </div>
        <div class="disclamer-box">
            <div class="disclamer-tit">
                <h3>隐私政策</h3>
            </div>
            <!-- <div class="disclamer-content">
                <p>本声明适用于河南千越医疗科技有限公司（以下简称“千越”或本公司）研发的所有平台软件（以下简称“平台”）。</p>
                <p>1、您使用系统的过程中，如果对本系统存在任何质疑，或不同意声明中的任何条款，当立刻停止使用本平台。如果您继续使用本平台，则表示您同意本声明中的所有条款。</p>
                <p>2、当您在使用本平台的过程中，由以下几种情况给您造成的损失，本公司不予承担任何责任：</p>
                <p class="s-p">(1)您通过非法渠道或方式，获取本平台的使用、服务等权利；</p>
                <p class="s-p">(2)因爲您的个人原因，造成账号和密码泄露，从而造成的任何损失；</p>
                <p class="s-p">(3)因爲您的操作不当或失误操作，造成的数据丢失、账号被盗等损失；</p>
                <p class="s-p">(4)因您个人或者您工作单位的电脑软件、系统、硬件、通讯、其它设备等损坏而造成损失；</p>
                <p class="s-p">(5)因您的网络安全而造成的损失，包括但不限于病毒、木马、黑客攻击或其它恶意破坏；</p>
                <p class="s-p">(6)基础运营商原因，包括但不限于电信部门技术调整、电信/电力线路被他人破坏、电信/电力部门对电信网络/电力资源进行安装、改造、维护；</p>
                <p class="s-p">(7)因不可抗力而造成的损失，包括但不限于自然灾害、罢工、动乱、政府行为、政策调整、法律法规调整；</p>
                <p class="s-p">(8)其他本公司无法控制或合理预见的情形。</p>
                <p>3、本公司根据市场环境、公司发展、产品升级等因素，会不定时对本声明进行调整、修改，如果您不同意修改或调整内容，可立刻停止使用本系统，并及时与本公司客服人员联系。如果您继续访问或使用本平台，则视为您已经接受本声明的修改或调整。</p>
                <p>4、本公司提供的平台、软件、服务等内容，将尽最大努力确保连贯性和安全性，但本公司不能保证本平台及本公司所提供的服务毫无瑕疵，且对可能存在的瑕疵不承担责任。</p>
                <p>5、本平台上所有的医学内容、资料、数据等仅供辅助参考, 不能也不应作为诊断及治疗的最终依据，最终的诊疗结果由使用本平台的医护人员作出判断，使用人或接受服务的人对使用本公司软件的后果承担风险，因此而发生的任何纠纷、赔偿、投诉、处罚、处分与本公司无关，本公司不承担任何责任。</p>
                <p>6、本公司对所有平台、软件等产品拥有著作权、商标权等知识产权，受相关法律、法规的保护。任何单位或个人不得在没有本公司授权的情况下对本公司开发的任何产品进行任何非法操作，包括但不限于反编译、二次开发、功能修改等。</p>
                <p>7、为了提供更加完善的服务，本公司有权定期或不定期地对服务平台或相关设备进行升级维护，包括但不限于检修、维护、升级等，届时本平台将提前12小时以平台消息通知的方式告知您，如因升级维护而造成本平台在合理时间内中断或暂停的，本公司不承担责任。</p>
                <p>8、如因网络状况、通讯线路等任何技术原因而导致升级维护失败、暂停等情况，本公司承诺将全力尽快处理问题，整个升级维护过程中，因您的不配合而造成的损失，本公司不承担责任。</p>
                <p>9、本声明未涉及的问题请参见国家有关法律法规，当本声明与国家有关法律法规冲突时，以国家法律法规为准。</p>
                <br>
                <br>
                <p>特此声明</p>
                <br>
                <p style="text-align: right">声明人：河南千越医疗科技有限公司</p>
            </div> -->
            <div class="disclamer-content">
                <p>欢迎使用“千越医疗”提供的产品和服务，我们注重保护您的隐私权，在此特别提醒您（用户）在注册成为用户之前，及在使用千越医疗提供的所有产品过程中，请认真阅读本《千越医疗隐私协议》（以下简称“协议”或“本协议”），确保您充分理解本协议中各条款。请您审慎阅读，并选择接受或不接受本协议。您的注册、登录、使用等行为，将视为对本协议的接受，并同意接受本协议各项条款的约束。</p>
                <p>第三方服务使用到SDK中在第六条（三）中有详细说明</p>
                <p>“千越医疗”“千越医助手”“千越仁医”“千越云His”“我司”“本网站”“我们”“本公司”及在此隐私协议中同类词语，均代表河南千越医疗科技有限公司。</p>
                <p>“您”“您的”“你”“你的”等在此隐私协议中的同类词语，均代表千越医疗的网站、产品及服务的用户。</p>
                <h3>【特别提示】</h3>
                <p>本协议适用于我们提供的所有产品及服务，包括APP、网站、客户端、小程序以及随技术发展出现的新形态，向您提供的各项产品和服务。未满十八周岁的用户，请在法定监护人的陪同下阅读本政策，并特别注意未成年人使用条款。</p>
                <p>本协议可由本公司随时更新，更新后的协议条款一旦公布，即代替原来的协议条款，恕不再另行通知，用户可在本公司产品中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用本公司提供的服务，用户继续使用服务将被视为接受修改后的协议。</p>
                <p>我们希望通过本协议，向您说明我们在收集和使用您相关个人信息时对应的处理规则，以及我们为您提供的访问、更正、删除和保护这些个人信息的方式，以便更好的保障您的权益。</p>
                <h3>一、个人信息的收集和使用</h3>
                <p>我们收集您的个人信息，主要是为了您和其他用户能够更容易和更满意地使用我司提供的所有服务。</p>
                <h4>（一）收集您的个人信息的途径</h4>
                <p>1、您在注册帐号或使用本公司服务时，向我们提供的个人信息，包括但不限于您的手机号码，昵称、头像、性别、年龄、学历、身份证号码、收件人姓名、收件人联系方式、收货地址等基本信息。当您使用手机App、小程序时，您还可自愿选择或填写您的地区、所属行业、单位名称、职称、职务等，以便帮助您注册使用千越医疗提供的所有服务，以及接受我司以后新增的其它服务。</p>
                <p>2、您通过千越医疗服务向其他方提供的共享个人信息，以及您使用千越医疗服务时所储存的个人信息。</p>
                <p>3、其他方分享的您的个人信息，即其他用户使用千越医疗服务时所提供有关您的共享个人信息。</p>
                <p>4、我们获取的您的个人信息。您在使用我司提供的产品或服务时，我们收集、汇总、记录的个人信息。</p>
                <h4>（二）收集和使用您个人信息的目的</h4>
                <p>1、帮助您完成注册、登录及注销</p>
                <p class="s-p">为便于我们为您提供完整的服务，您需要提供基本注册或登录个人信息，包括使用手机号码注册、登录或提供第三方账号登录千越医疗</p>
                <p>2、客服与售后</p>
                <p class="s-p">依照相关法律法规规定及监管要求，或当您与我们联系时，我们的客服或售后可能会需要您提供真实身份信息（姓名、身份证号和账号）等信息，以核验您的身份，以便帮助您问题，或记录相关问题的处理方案及结果。</p>
                <p class="s-p">请注意：您的身份信息属于个人敏感信息，请您谨慎提供，如果拒绝提供，您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。</p>
                <p>3、维护基础功能的正常运行</p>
                <p class="s-p">在您使用我们服务过程中，为识别账号异常状态、了解产品适配性，向您提供浏览、搜索、注册/登录、查看和编辑个人资料、购买及观看视频、邮寄、支付的服务，维护基础功能的正常运行，我们可能会自动收集、储存关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</p>
                <p class="s-p">（1）日志信息：当您使用我们的服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。日志信息包括您的登录账号、搜索查询内容、IP地址、浏览器的类型、网络环境、访问日期和时间、收藏及分享内容。</p>
                <p class="s-p">（2）设备信息：我们可能会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息，包括但不限于设备标识符（IMEI/ MAC/OPEN UDID）、设备机型、操作系统及版本、客户端版本、设备所在位置等。</p>
                <p>4、向您提供商品或服务</p>
                <p class="s-p">（1）个人信息的分享与发布：您在使用千越医疗服务时，上传和/或发布个人信息以及进行相关行为时，我们将收集您上传、发布或形成的个人信息，并有权展示您的昵称、头像和发布内容。例如：您在本网站内发布视频或留言、提交内容及商品/服务评价等行为。</p>
                <p class="s-p">（2）商品或服务交付：当您在我们的商品或服务中订购具体商品或服务时，我们会通过系统为您生成购买该商品或服务的订单。为便于向您交付您在商城内购买的商品或服务，您可能需提供收货人个人身份信息、姓名、收货地址、收货人、联系电话、支付状态信息。如果您拒绝提供此类个人信息，我们将无法完成相关交付服务。</p>
                <p class="s-p">（3）为完成订单支付、交付商品或服务、确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构、物流公司等收集与交易进度相关的您的账号、订单、交易、支付、物流/快递信息，或将您的交易信息共享给上述服务提供者。</p>
                <p class="s-p">（4）订单管理：为展示您的账户的订单信息及保障您的售后权益，我们会收集您在使用千越医疗过程中产生的订单信息、交易和消费记录、虚拟财产信息，用于向您展示及便于您对订单进行管理。</p>
                <p class="s-p">（5）客服与售后服务：当您联系我们的客服或使用其他功能时（包括提出售后申请、个人信息保护投诉或建议、投诉和需求），我们可能需要您提供必要的个人信息以匹配并核验您的用户身份，以便保障您的账号与系统安全。我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息（以上信息中可能会涉及您的个人敏感信息），以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</p>
                <p>5、间接方式收集到的您的个人信息</p>
                <p>基于您选择使用的千越医疗服务，我们可能从关联方、第三方合作伙伴获取您授权共享的相关信息（包括：用户名、昵称、头像，具体以您的授权内容为准），并在您同意本政策后将您的第三方帐号与您的千越医疗帐号绑定，使您可以通过第三方帐号直接登录并使用本服务。我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息。</p>
                <p>6、向您提供商品或服务的信息展示和推送</p>
                <p class="s-p">（1）内容推荐。我们可能会基于您的个人信息，进行推荐、建模、用户行为分析及用户画像相关特征，以便向您提供更契合您需求的展示和推荐个性化内容。</p>
                <p class="s-p">（2）向您发出通知。对于您在使用服务过程中，提供的您的联系方式，我们可能在运营中向您发出与服务有关的通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研等用途。此外，我们也可能通向您发送您可能感兴趣的服务、功能或活动。
                如您不希望继续接收我们推送的消息，您可要求我们停止推送，不再接收我们推送的消息；但我们依法律规定或服务协议约定发送消息的情形除外。
                </p>
                <p>7、为您提供安全保障</p>
                <p>为提高您使用我们提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或千越医疗服务相关协议规则的情况，我们可能会收集、使用或整合您的账户信息、交易信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的个人信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</p>
                <p>8、迭代升级</p>
                <p>我们希望提供给您的产品和服务是完善的，所以我们会不断改进我们的产品和服务，我们可能将通过服务所收集的个人信息，来诊断系统问题，优化产品体验、软件更新、改善现有服务或设计新服务。</p>
                <p>9、征得授权同意的例外</p>
                <p>根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</p>
                <p class="s-p">（1）与个人信息控制者履行法律法规规定的义务相关的；。</p>
                <p class="s-p">（2） 与国家安全、国防安全直接相关的；</p>
                <p class="s-p">（3） 与公共安全、公共卫生、重大公共利益直接相关的；</p>
                <p class="s-p">（4） 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
                <p class="s-p">（5） 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</p>
                <p class="s-p">（6） 所涉及的个人信息是个人信息主体自行向社会公众公开的；</p>
                <p class="s-p">（7） 根据个人信息主体要求签订和履行合同所必需的；</p>
                <p class="s-p">（8） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
                <p class="s-p">（9） 维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障。</p>
                <h3>二、使用Cookies或同类技术</h3>
                <p>我们或我们的第三方合作伙伴可能通过COOKIES获取和使用您的个人信息，并将该等个人信息储存为日志信息。</p>
                <p>通过使用COOKIES，我们向您提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让您可以受益。比如，为使得千越医疗的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录时能更加方便快捷。</p>
                <p>COOKIES使得我们能更好、更快地为您服务，并且使您在千越医疗服务上的经历更富个性化。</p>
                <p>我们使用自己的COOKIES，可能用于以下用途：</p>
                <p class="s-p">（1）记住您的身份。COOKIES有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他个人信息；</p>
                <p class="s-p">（2）分析您使用我们服务的情况。我们可利用COOKIES来了解您使用千越医疗服务进行什么活动、或哪些服务或服务最受欢迎；</p>
                <p class="s-p">（3）广告优化。COOKIES有助于我们根据您的个人信息，向您提供与您相关的广告而非进行普遍的广告投放。</p>
                <h3>三、共享、转让或披露的个人信息</h3>
                <h4>（一）共享</h4>
                <p>您同意我们依据本政策向我们的关联方或相关法人团体、与我们合作的一些商业合作伙伴（合称“信息接收方”）共享您的个人信息。我们将积极帮助您了解信息接收方对个人信息的存储、使用等情况，以及您享有的访问、更正、删除、撤回同意、注销账户等权利。</p>
                <p>除以下情形外，未经您同意，我们不会与我们及我们的信息接收方之外的任何第三方共享您的个人信息：</p>
                <p>1、为实现我们的服务/功能所必须进行的共享。我们可能向信息接收方共享您的个人信息，以实现您需要的核心功能或提供您需要的服务，包括但不限于向物流服务商提供对应的订单信息；向提供支付服务的第三方支付机构共享对应的交易订单信息和支付金额信息；其他与我们的服务/功能相关的供应商、服务提供商和业务合作伙伴所必须进行的共享。</p>
                <p>2、与我们的部分合作伙伴的必要共享。为了必要/合理的业务的顺利开展、满足您的要求、履行我们在相关用户协议或本政策中的义务和行使我们的权利或遵守法律规定等目的，我们可能需要向部分合作伙伴共享您的信息，包括但不限于向提供数据服务的合作伙伴共享日志信息、设备信息，包括数据统计、数据分析等；向提供产品分析服务的合作伙伴共享您的产品使用情况（如崩溃、闪退）的统计性数据，包括应用崩溃分析、网络性能分析、域名解析优化；其他与我们的部分合作伙伴的必要共享。</p>
                <p>3、与我们的关联方的必要共享。为便于我们基于统一的帐号体系向您提供一致化服务以及便于您进行统一管理、个性化推荐、保障系统和帐号安全等，您的个人信息可能会在我们和我们的关联方之间进行必要共享，包括为了帮助您节省时间、简化操作流程，您在注册我们的帐号后无需重复注册即可登录部分服务，您的帐号相关信息会在我们和我们的关联方的产品内部进行必要共享；为了使您在我们的及我们关联方产品与/或服务有一致性和个性化的浏览、广告等服务体验，您知悉并同意，您的帐号信息、设备信息、相关浏览数据等反映您使用习惯和爱好的信息可能会与我们的关联方共享。</p>
                <p>4、实现本协议第二条“收集和使用您的个人信息的目的”部分所述目的；</p>
                <p>5、履行我们在本协议或我们与您达成的其他协议中的义务和行使我们的权利；</p>
                <p>6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他千越医疗用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换个人信息。不过,这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。</p>
                <p>7、应您合法需求，协助处理您与他人的纠纷或争议；</p>
                <p>8、根据与您签署的服务协议或其他的法律文件约定所提供；</p>
                <p>9、基于学术研究而提供；</p>
                <p>10、基于符合法律法规的社会公共利益而提供。</p>
                <p>我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
                <h4>（二）转让</h4>
                <p>1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>
                <p>2、在获得您的明确同意后，我们会向其他方转让您的个人信息。</p>
                <h4>（三）披露</h4>
                <p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：</p>
                <p>1、根据您的需求，在您明确同意的披露方式下，披露您所指定的个人信息；</p>
                <p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露个人信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的个人信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</p>
                <h4>（四）例外情况</h4>
                <p>以下情形中，我司共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
                <p>1、与个人信息控制者履行法律法规规定的义务相关的；</p>
                <p>2、与国家安全、国防安全有关的；</p>
                <p>3、与公共安全、公共卫生、重大公共利益有关的；</p>
                <p>4、与刑事侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
                <p>5、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
                <p>6、您自行向社会公众公开的个人信息；</p>
                <p>7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
                <p>根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
                <h3>四、个人信息安全的保护和存储</h3>
                <h4>（一）限期保留</h4>
                <p>除法律法规或监管部门另有规定外，我们仅在本协议所述目的所必需期间且最短时限内存储您的个人信息。我们判断前述期限的标准包括：</p>
                <p>1、完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；</p>
                <p>2、保证平台为您提供服务的安全和质量；</p>
                <p>3、您是否同意更长的留存期间；</p>
                <p>4、是否存在保留期限的其他特别约定。</p>
                <p>5、法律法规或监管部门规定的期限。</p>
                <p>如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在本政策所述目的所必须期间、法律法规及监管要求的时限届满后，或终止服务或运营后对您的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。</p>
                <h4>（二）地域存储</h4>
                <p>我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内。以下情形除外：</p>
                <p>1、法律法规有明确规定；</p>
                <p>2、获得您的授权同意；</p>
                <p>3、您使用的产品、服务涉及跨境，我们需要向境外提供您的个人信息的。</p>
                <p>针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。</p>
                <h4>（三）安全措施</h4>
                <p>我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</p>
                <p>1、数据安全技术措施 </p>
                <p>我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。</p>
                <p>2、其他安全措施</p>
                <p class="s-p">（1）我们通过建立数据分类、数据安全管理、数据安全开发来管理规范个人信息的存储和使用。</p>
                <p class="s-p">（2）我们通过个人信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</p>
                <p class="s-p">（3）我们还会举办安全和隐私保护培训，加强员工对于保护个人信息重要性的认识和安全意识。</p>
                <p class="s-p">（3）我们还会举办安全和隐私保护培训，加强员工对于保护个人信息重要性的认识和安全意识。</p>
                <p class="s-p">（4）我们仅允许有必要知晓这些个人信息的我们及我们关联方的员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。</p>
                <p>3、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</p>
                <p>4、我们将尽力确保或担保您发送给我们的任何个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致个人信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
                <h4>（四）安全事件处置</h4>
                <p>如不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、短信、电话、推送通知等方式告知您。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                <p>请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证个人信息百分之百的安全。您需要了解，您接入千越医疗服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</p>
                <h3>五、管理您的个人信息</h3>
                <p>我们鼓励您更新和修改您的个人信息以使其更准确有效，也请您理解，您更正、删除、撤回授权或停止使用千越服务的决定，并不影响我们此前基于您的授权而开展的个人信息处理。</p>
                <p>您可以通过以下方式来管理您的个人信息： </p>
                <h4>（一）访问您的个人信息</h4>
                <p>1、账户个人信息：您可以登录账号通过“我的—设置—个人资料”进行访问。</p>
                <p>2、订单个人信息：您可以通过“我的—我的订单”查看您的所有订单状态。</p>
                <h4>（二）更正您的个人信息</h4>
                <p>如果您希望更正您的账户中的个人信息，您可以登录账号通过“我的—设置—个人资料”进行修改。</p>
                <h4>（三）删除您的个人信息或您的账户</h4>
                <p>您可以通过联系我们客服人员或者您所在机构，按照约定的方式，删除您的账户，在部分个人信息删除或删账户时，我们可能会要求您进行身份验证，以保障个人信息安全。</p>
                <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
                <p>1、如果我们处理个人信息的行为违反法律法规；</p>
                <p>2、如果我们收集、使用您的个人信息，却未征得您的授权同意；</p>
                <p>3、如果我们处理个人信息的行为严重违反了与您的约定；</p>
                <p>4、如果我们不再为您提供产品、服务。</p>
                <h3>六、第三方服务</h3>
                <p>千越医疗服务可能链接至第三方提供的社交媒体或其他服务，包括网站或其他服务形式，包括</p>
                <h4>（一）您可利用“分享”功能，将某些千越医疗服务内容分享到第三方服务，或您将第三方服务内容分享到千越医疗。这些功能可能会收集您的信息，并可能在您的COOKIES，从而正常运行上述功能；</h4>
                <h4>（二）我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站；</h4>
                <h4>（三）其他接入第三方服务的情形。为实现本协议中声明的目的，我们可能会接入第三方服务商提供的SDK或其他类似的应用程序，并将我们依照本协议收集的您的某些信息共享给该等第三方服务商，以便提供更好的客户服务和用户体验。目前，我们接入的第三方服务商主要包括以下几种类型：</h4>
                <p class="s-p">（1）用于消息推送功能，包括推送、特定事件提醒等；。</p>
                <p class="s-p">（2）用于支付相关服务，包括订单支付、交易核验、收入结算、支付信息汇总统计等；</p>
                <p class="s-p">（3）用于在您同意的情况下获取设备位置权限、搜集设备信息和日志信息等；</p>
                <p class="s-p">（4）用于第三方授权服务，包括第三方帐号登陆、将有关内容分享至第三方产品等；</p>
                <p class="s-p">（5）用于支持产品功能模块，包括视频播放、语音识别、图像识别、内容存储等；</p>
                <p class="s-p">（6）SDK名称：APP支付客户端SDK 开发者:支付宝(杭州)信息技术有限公司 SDK隐私政策链接：https://opendocs.alipay.com/open/54/01g6qm#%E6%94%AF%E4%BB%98%E5%AE%9D%20App%20%E6%94%AF%E4%BB%98%E5%AE%A2%E6%88%B7%E7%AB%AF%20SDK%20%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96;</p>
                <p class="s-p"> (7) SDK名称：在线语音合成SDK（Android） 开发者:北京百度网讯科技有限公司 SDK隐私政策链接：https://ai.baidu.com/ai-doc/REFERENCE/Jkdyl0v4v;</p>
                <p class="s-p">（8）SDK名称：地图SDK 开发者:高德软件有限公司 SDK隐私政策链接：https://lbs.amap.com/pages/privacy/;</p>
                <p class="s-p">（9）SDK名称：微信OpenSDK Android 开发者:深圳市腾讯计算机系统有限公司 SDK隐私政策链接https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8;</p>
                <p class="s-p">（10）SDK名称：轻量版地图SDK 开发者:高德软件有限公司 SDK隐私政策链接：https://lbs.amap.com/pages/privacy/;</p>
                <!-- <p class="s-p">（6）SDK名称：在线语音合成SDK（Android） 开发者:北京百度网讯科技有限公司 SDK隐私政策链接：https://ai.baidu.com/ai-doc/REFERENCE/Jkdyl0v4v;</p>
                <p class="s-p">（7）SDK名称：定位SDK 开发者:高德软件有限公司 SDK隐私政策链接：https://lbs.amap.com/pages/privacy/;</p>
                <p class="s-p">（8）SDK名称：微信OpenSDK Android 开发者:深圳市腾讯计算机系统有限公司 隐私链接：https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</p> -->
                <h3>七、未成年人保护</h3>
                <p>如您为未满18周岁的未成年人的，请务必让您的监护人仔细阅读本协议，并在征得您的监护人同意的前提下，使用我们的服务或向我们提供个人信息。我们将根据国家相关法律法规的规定保护未成年人的相关个人信息。</p>
                <p>如监护人发现相关未成年人个人信息为未成年人自行填写，需要进行修改或删除处理的，请随时与我们联系。</p>
                <h3>八、通知和修订</h3>
                <p>为了给您提供更好的服务，我们可能会根据千越医疗服务的更新情况及法律法规的相关要求，适时修改本协议的条款，修改内容为本协议的一部分。</p>
                <p>我们会在本页面上公布对本政策所作的任何变更。对于重大变更，我们还会提供更为显著的通知，包括网站公告、推送通知、弹窗提示或其他方式中的一种或多种方式。</p>
                <p>本政策所指的重大变更包括但不限于：</p>
                <p>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
                <p>2、我们在所有权结构方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
                <p>3、个人信息共享、转让或公开披露的主要对象发生重大变化；</p>
                <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
                <p>5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生重大变化；</p>
                <p>6、其他重要的或可能严重影响您的个人权益的情况发生。</p>
                <h3>九、法律适用与管辖</h3>
                <p>本协议适用中华人民共和国的法律（不含冲突法）。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关内容将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。</p>
                <p>凡因本协议引起的或与本协议有关的任何争议,双方应协商解决。协商不成时，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。</p>
                <h3>十、如何联系我们</h3>
                <p>电话：0395-3393819</p>
                <p>邮箱：services@qyyl365.com</p>
                <p>地址：河南省漯河市郾城区黄河路与金山路交叉口东北角昌建东外滩A2幢2208号</p>
                <p>一般情况下，我们将在收到您的问题、意见或建议之日起，十五天内予以回复。</p>
                <br>
                <br>
                <p>特此声明</p>
                <br>
                <p style="text-align: right">声明人：河南千越医疗科技有限公司</p>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import ftCom from '../../components/footer';
    import nhCom from "../../components/navheader";
    import rtBar from "../../components/right";
    export default {
        name: "disclaimer",
        components: {
            ftCom,
            nhCom,
            rtBar
        },
    }
</script>

<style scoped>
    .disclaimer{
        background: #f4f4f4;
    }
    .xi .banner{
        height: 116px;
        background: url("../../assets/img/jbk_banner.png")center no-repeat;
    }
    .banner-text{
        width: 1200px;
        margin: 0 auto;
    }
    .banner-text h3{
        padding-top: 25px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }
    .banner-text p{
        font-size: 18px;
        color: #ffffff;
    }
    .disclamer-box {
        margin: 30px auto;
        /* width: 1200px; */
        width: 90%;
        padding: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #e5e5e5;
    }
    .disclamer-tit{
        padding: 10px 0 20px;
        border-bottom: 1px solid #e5e5e5;
    }
    .disclamer-tit h3{
        font-size: 20px;
        color: #333333;
        text-align: center;
        font-weight: bold;
    }
    .disclamer-content{
        padding: 25px 0;
    }
    .disclamer-content p{
        text-indent: 28px;
        line-height: 2;
    }
    .disclamer-content p.s-p{
        text-indent: 42px;
    }
    .disclamer-content h4{
        text-indent: 28px;
        line-height: 1.7;
        padding: 15px 0;
    }
</style>

<template>
  <div class="inteBox">
    <intNavheader activeNameCheck="fourth" :Partype="zjExtype"></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <!-- <div :class="userInfo.isAuditDiagnose == 'True' ? 'titleTopCenterLeftBox' : 'titleTopCenterLeftBoxno'" @click="goDoctor">处方审核</div> -->
        <waitNumber :waitDataTotalNumber='waitDataTotal'></waitNumber>
      </div>
      <div class="titleTopCenterCenter">
        <div class="titleTopCenterCenterTop">
          <el-tabs class="Content1Title" v-model="activeName" @tab-click="handleClick">
              <div class="flex-l" style="margin-top: 20px">
                <el-form :model="searchForm" :inline="true">
                  <el-form-item>
                    <el-date-picker v-model="searchForm.datepicker" type="daterange"  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px"> </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="患者手机号/患者名称" clearable></el-input>
                  </el-form-item>
                   <el-form-item label="">
                      <el-select v-model="searchForm.state" placeholder="请选择" size="small">
                        <el-option
                          v-for="item in Searchoptions"
                          :key="item.state"
                          :label="item.label"
                          :value="item.state">
                        </el-option>
                      </el-select>
                   </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="small" @click="Search">搜索</el-button>
                  </el-form-item>
                </el-form>
              </div>
            <el-tab-pane label="我的审核" name="2">
              <div class="flex-l" style="margin-top: 20px">
                <!-- <el-form :model="searchForm" :inline="true">
                  <el-form-item>
                    <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px"> </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="患者手机号/患者名称" clearable></el-input>
                  </el-form-item>
                   <el-form-item label="">
                      <el-select v-model="searchForm.state" placeholder="请选择" size="small">
                        <el-option
                          v-for="item in Searchoptions"
                          :key="item.state"
                          :label="item.label"
                          :value="item.state">
                        </el-option>
                      </el-select>
                   </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="small" @click="Search">搜索</el-button>
                  </el-form-item>
                </el-form> -->
              </div>
              <el-table :data="tableData" style="width: 100%" height="calc(100vh - 234px)">
                <el-table-column prop="patientName" label="姓名" align="center" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.patientName }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="手机号" align="center" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.phone }}</div>
                  </template>
                </el-table-column>
                
                <el-table-column prop="diseaseDiagnose" label="诊断" align="center">
                  <template slot-scope="scope">
                    <div>{{ scope.row.diseaseDiagnose }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="userName" label="提交医生" align="center" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.userName }}</div>
                    <div>{{ scope.row.userPhone }}</div>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="waitAuditDoctorPhone" label="审核药剂师" align="center" width="180">
                  <template slot-scope="scope">
                    <div>{{ scope.row.waitAuditPharmacistName ? scope.row.waitAuditPharmacistName :  '' }}</div>
                    <div>{{ scope.row.waitAuditPharmacistPhone ? scope.row.waitAuditPharmacistPhone :  '' }}</div>
                  </template>
                </el-table-column> -->
                <el-table-column prop="addTime" label="提交日期" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.addTime | timefilters }}
                  </template>
                </el-table-column>
                <el-table-column prop="auditStatus" label="状态" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.auditStatus == -1" style="color: #ff1c1c">审核拒绝</div>
                    <div v-if="scope.row.auditStatus == -2" style="color: #ff1c1c">药剂师拒绝</div>
                    <div v-if="scope.row.auditStatus == 0" style="color: #5a5fc8">待审核<span v-if="scope.row.auditStatus == 0 && scope.row.SYAuditTime" style="color:#FF0000;margin-left:10px">(倒计时: {{scope.row.SYAuditTime}})</span></div>
                    <div v-if="scope.row.auditStatus == 1" style="color: #00afb5">审核通过</div>
                    <div v-if="scope.row.auditStatus == 2" style="color: #00afb5">待取药</div>
                    <div v-if="scope.row.auditStatus == 3" style="color: #00afb5">已完成</div>
                    <div v-if="scope.row.auditStatus == 4" style="color: #00afb5">备药中</div>
                    <div v-if="scope.row.auditStatus == 5" style="color: #e6a23c">药剂师待审核</div>
                    <div v-if="scope.row.auditStatus == 6" style="color: #e6a23c">审核中</div>
                    
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                  <template slot-scope="scope">
                    <el-button @click="goseeDoctor2(scope.row)" type="text" size="small" v-if="scope.row.auditStatus  == 0" style="color: #01A0A5">开始审核</el-button>
                    <el-button v-else @click="goseeDoctor1(scope.row)" type="text" size="small">详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </el-tab-pane>
              <el-tab-pane label="我的提交" name="1">
              <div class="flex-l" style="margin-top: 20px">
              </div>
              <el-table :data="tableData" style="width: 100%" height="calc(100vh - 234px)">
                <el-table-column prop="patientName" label="姓名" align="center" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.patientName }}</div>
                  </template>
                </el-table-column>
                   <el-table-column prop="phone" label="手机号" align="center" width="120">
                  <template slot-scope="scope">
                    <div>{{ scope.row.phone }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="diseaseDiagnose" label="诊断" align="center">
                  <template slot-scope="scope">
                    <div>{{ scope.row.diseaseDiagnose }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="waitAuditDoctorName" label="审核医生" align="center" width="120">
                  <template slot-scope="scope">
                    <div v-if="scope.row.auditStatus == 0">
                        <div>{{ scope.row.waitAuditDoctorName != 0 ? scope.row.waitAuditDoctorName :  '暂未选择'}}</div>
                        <div>{{ scope.row.waitAuditDoctorPhone != 0 ? scope.row.waitAuditDoctorPhone :  '' }}</div>
                    </div>
                    <div v-else>
                        <div>{{ scope.row.waitAuditDoctorName != 0 ? scope.row.waitAuditDoctorName :  scope.row.userName }}</div>
                        <div>{{ scope.row.waitAuditDoctorPhone != 0 ? scope.row.waitAuditDoctorPhone :  scope.row.userPhone }}</div>
                    </div>
             
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="waitAuditDoctorPhone" label="审核药剂师" align="center" width="180">
                  <template slot-scope="scope">
                    <div>{{ scope.row.waitAuditPharmacistName ? scope.row.waitAuditPharmacistName : '' }}</div>
                    <div>{{ scope.row.waitAuditPharmacistPhone ? scope.row.waitAuditPharmacistPhone :  '' }}</div>
                  </template>
                </el-table-column> -->
                <el-table-column prop="addTime" label="提交日期" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.addTime | timefilters }}
                  </template>
                </el-table-column>
                <el-table-column prop="auditStatus" label="状态" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.auditStatus == -1" style="color: #ff1c1c">审核拒绝</div>
                    <div v-if="scope.row.auditStatus == -2" style="color: #ff1c1c">药剂师拒绝</div>
                    <div v-if="scope.row.auditStatus == 0" style="color: #5a5fc8">待审核<span v-if="scope.row.auditStatus == 0 && scope.row.SYAuditTime" style="color:#FF0000;margin-left:10px">(倒计时: {{scope.row.SYAuditTime}})</span></div>
                    <div v-if="scope.row.auditStatus == 1" style="color: #00afb5">审核通过</div>
                    <div v-if="scope.row.auditStatus == 2" style="color: #00afb5">待取药</div>
                    <div v-if="scope.row.auditStatus == 3" style="color: #00afb5">已完成</div>
                    <div v-if="scope.row.auditStatus == 4" style="color: #00afb5">备药中</div>
                    <div v-if="scope.row.auditStatus == 5" style="color: #e6a23c">药剂师待审核</div>
                    <div v-if="scope.row.auditStatus == 6" style="color: #e6a23c">审核中</div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200">
                  <template slot-scope="scope">
                    <el-button @click="goseeAuidtDoctor(scope.row)" type="text" size="small" v-if="scope.row.auditMoneyRefund  == 1" style="color: #e6a23c">重新审核</el-button>
                    <el-button v-else @click="goseeDoctor(scope.row)" type="text" size="small">详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="titleTopLine"></div>
      <!-- 处方审核选择医生 -->
    <el-dialog title="提示" :visible.sync="doctorDialogVisible" width="60%" :show-close="false" :close-on-click-modal="false">
      <div class="">
        <!-- <el-steps v-if="!AuditNextDataSuc"  :active="doctorDialogProgress" finish-status="success" simple style="margin-bottom: 20px">
          <el-step title="1.选择审核医生"></el-step>
          <el-step title="2.选择药剂师"></el-step>
          <el-step title="3.支付" ></el-step>
        </el-steps>
        <el-steps v-else  :active="doctorDialogProgress" finish-status="success" simple style="margin-bottom: 20px">
          <el-step title="1.选择药剂师"></el-step>
          <el-step title="2.支付" ></el-step>
        </el-steps> -->
        <el-steps  :active="doctorDialogProgress" finish-status="success" simple style="margin-bottom: 20px">
          <el-step title="1.选择审核医生"></el-step>
          <el-step title="2.支付" ></el-step>
        </el-steps>
        <div class="">
           <!-- && !AuditNextDataSuc -->
          <div class="flex-l" v-if="doctorDialogProgress == 1">
            <el-button style="margin: 10px;margin-top:0" :type="indexTypeDoctor == 0 ? 'primary' : ''" size="small"
              @click="auditkeyDoctor(0)">本院医生</el-button>
            <el-button style="margin: 10px;margin-top:0;margin-left:0" :type="indexTypeDoctor == 1 ? 'primary' : ''"
              size="small" @click="auditkeyDoctor(1)">平台专家</el-button>
          </div>
          <!-- <div class="flex-l" v-if="doctorDialogProgress == 2">
            <el-button style="margin: 10px;margin-top:0">药剂师</el-button>
          </div> -->
          <div class="flex-l" v-if="doctorDialogProgress != 3">
            <el-input v-model="auditkeyWord" placeholder="姓名/电话" style="width: 20%; margin: 0 10px"></el-input>
            <el-select v-model="depvalue" placeholder="请选择部门" @change="depvalueChange" clearable style="width: 15%; margin: 0 10px">
              <el-option style="width: 30%; margin: 0 10px" v-for="(item, index) in depoptions" :key="index"
                :label="item.name" :value="item.id"> </el-option>
            </el-select>
            <div class="flex-l" style="margin: 0 10px">
              <span>价格区间</span>
              <el-input-number :controls="false" v-model="priceKeyWordmin" placeholder="0" style="width: 10%;margin: 0 5px"></el-input-number>
              <span>-</span>
              <el-input-number :controls="false" v-model="priceKeyWordmax" placeholder="0" style="width: 10%;margin: 0 5px"></el-input-number>
              <el-button style="margin: 0 10px" @click="auditkeySearch" type="primary">搜索</el-button>
            </div>
          </div>
          <div class="flex-l-b1" style="min-height:300px;margin-top:10px" v-if="doctorDialogProgress == 1">
            <div style="width: 65%;">
              <div style="max-height:400px;min-height:200px;overflow-y: auto">
                <el-table ref="singleTable" :data="AuditDoctorList" style="width: 100%" highlight-current-row
                  @current-change="handleSelectionChangeAudit">
                  <el-table-column type="index" width="55"> </el-table-column>
                  <el-table-column label="姓名" width="120">
                    <template slot-scope="scope">{{ scope.row.userName }}</template>
                  </el-table-column>
                  <el-table-column prop="adviceContent" label="部门">
                    <template slot-scope="scope">{{ scope.row.department ? scope.row.department.name : '暂无'
                      }}</template>
                  </el-table-column>
                  <el-table-column prop="addTime" label="联系方式" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.phone }}</template>
                  </el-table-column>

                  <el-table-column prop="auditCount" label="待审核处方" show-overflow-tooltip align='center'>
                    <template slot-scope="scope">
                      <div class="dangeGreen">
                        {{ scope.row.auditCount ? scope.row.auditCount : 0 }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="auditDiagnoseMoney" label="审核费用" show-overflow-tooltip align='center'>
                    <template slot-scope="scope">
                      <div class="danger">
                        {{ scope.row.auditDiagnoseMoney ? scope.row.auditDiagnoseMoney + "元" : 0 }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>

              </div>

              <div class="pageBox">
                <el-pagination background layout="prev, pager, next" :page-size="15"
                  @current-change="AuditCurrentChange" :current-page.sync="AuditcurrentPage" :total="auditTotal">
                </el-pagination>
              </div>
            </div>
            <div style="width: 30%;font-size:16px;margin-top:-40px">
              <div class="noImgData" v-if="JSON.stringify(auditDetail) == '{}'">
                <img src="@/assets/img/nodata1.png" alt="">
                <div style="text-align: center;color:#999999">暂未选择审核医生~</div>
              </div>
              <div v-else>
                <div class="flex-l">
                  <div class="elImagePho">
                    <img v-if="auditDetail.photoFile" :src="Services.ossUpload + auditDetail.photoFile" alt="">
                    <img v-else src="@/assets/img/head_img.png" />
                  </div>
                  <div>
                    <div class="flex-l" style="margin-left:10px">
                      <div style="font-size:18px;font-weight: bold;">{{ auditDetail.userName }}</div>
                      <div style="margin: 0 10px">{{ auditDetail.department ? auditDetail.department.name : '' }}</div>
                      <div>{{ auditDetail.userPost ? auditDetail.userPost.userPostName : '' }}</div>
                    </div>
                    <div>
                      <div style="margin-left:10px;">{{ auditDetail.organization ? auditDetail.organization.name : '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div style="margin-top:15px">{{ auditDetail.profile }}</div>
                </div>
                <div style="margin-top:15px">
                  <div style="max-height:300px;overflow-y: auto;"><span
                      style="color:#333333;font-weight: bold;">个人介绍:</span> <span v-if="auditDetail.abstract"
                      v-html="auditDetail.abstract"></span> <span v-else>暂未填写</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="doctorDialogProgress == 3">
            <el-form class="dialog-form" :model="charge" ref="chargeForm">
              <el-form-item label="应收金额" style="color: #ff0000" class="numT">
                <span style="font-size: 25px"><span style="font-size: 14px">￥</span>{{ charge.totalMoney ?
                  (charge.totalMoney - charge.totalPayMoney).toFixed(2) : "0.00" }}</span>
              </el-form-item>
              <template v-if="charge.credit">
                <el-form-item label="姓名" prop="debtUserName">
                  <el-input v-model="charge.debtUserName" style="width: 80%" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="debtUserPhone">
                  <el-input v-model="charge.debtUserPhone" style="width: 80%" clearable></el-input>
                </el-form-item>
                <el-form-item label="挂账金额" prop="debtMoney">
                  <el-input-number v-model="charge.debtMoney" size="small" controls-position="right" :precision="2" :step="1"
                    :min="1" class="leftInput" @change="checkDebtMoney" style="width: 150px"></el-input-number>
                </el-form-item>
              </template>
              <el-form-item label="付款方式">
                <el-radio-group v-model="charge.payWay" @input='chargePayWayInput'>
                  <el-radio :label="1" :disabled="payAuditloadingTrue">微信</el-radio>
                  <el-radio :label="2">支付宝</el-radio>
                  <el-radio :label="0" :disabled="assetsBalanceIsTrue">余额</el-radio><span
                    style="font-size: 14px; color: #ff0000">（可用余额：￥{{ UserBalance.assetsBalance }}）</span>
                </el-radio-group>
              </el-form-item>
              <template v-if="charge.payWay == 0">
                <el-form-item label="支付密码" prop="payPwd"> <el-input type="password" maxlength="6" v-model="charge.payPwd"
                    show-password style="width: 200px"></el-input><span
                    style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer" @click="getPayPwd">忘记密码？</span>
                </el-form-item>
              </template>
              <template v-if="charge.payWay == 1">
                <div class="flex-space-between-columns" v-if="qRcodeUrl">
                  <img style="width: 120px; height: 120px" :src="qRcodeUrl" alt="Base64 Image"  />
                  <div>
                    <span>打开微信扫码支付~ </span>
                    <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
                  </div>
                </div>
              </template>
              <template v-if="charge.payWay == 2">
                <div class="flex-space-between-columns" v-if="qRcodeUrlZ">
                  <iframe
                    :srcdoc="qRcodeUrlZ"
                    frameborder="no"
                    border="0"
                    marginwidth="0"
                    marginheight="0"
                    scrolling="no"
                    width="200"
                    height="200"
                    style="overflow: hidden;margin:10px 0"
                  >
                  </iframe>
                  <div>
                    <span>打开支付宝扫码支付~ </span>
                    <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span>
                  </div>
                </div>
              </template>
            </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="doctorDialogProgress == 1">
        <el-button @click="cancledialogVisible">取 消</el-button>
        <el-button type="primary" @click="adddialogVisible">下一步</el-button>
      </span>
      <!-- <span slot="footer" class="dialog-footer" v-if="doctorDialogProgress == 2">
        <el-button @click="cancledialogVisible">取 消</el-button>
        <el-button type="primary" @click="stepadddialogVisible">上一步</el-button>
        <el-button type="primary" @click="addDrugVisible">下一步</el-button>
      </span> -->
      <span slot="footer" class="dialog-footer" v-if="doctorDialogProgress == 3">
        <el-button @click="cancledialogVisible1">取 消</el-button>
        <el-button type="primary" @click="firstDialogVisible">上一步</el-button>
        <el-button type="primary" @click="addpayVisible" :loading="payAuditloading">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import intNavheader from "../../components/intNavheader";
import waitNumber from "../../components/waitNumber";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
import { shoppingMall } from "../../components/shoppingMall/shoppingMall";
import { Outpatient } from "@/components/Drug/Outpatient";
export default {
  components: {
    intNavheader,
    waitNumber
  },
  data() {
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    var outpatientAuthorization = new Outpatient(this.TokenClient, this.Services.Authorization);
    var UserDistributorltem = new shoppingMall(this.TokenClient, this.Services.Authorization);
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    var UserDistributorltemDrug = new shoppingMall(this.TokenClient, this.Services.Drug);
    return {
      zjExtype:this.$route.query.type,
      patientDomain: patient,
      drugpatientDomain: drugpatient,
      outpatientAuthorizationDomain: outpatientAuthorization,
      UserDistributorMain: UserDistributorltem,
      OutpatientDomain: outpatient,
      UserDistributorltemDrugMain: UserDistributorltemDrug,
      activeName: "2",
      addtoNew: false,
      inputValue: "",
      inputValue2: "",
      num: "",
      patientForm: {
        age: "",
        value: "",
        name: "",
      },
      searchForm: {
        datepicker: "",
        keyWord: "",
        state:'-999'
      },
      Searchoptions:[{
         state: '-999',
        label: '全部'
      },
      {
         state: '0',
        label: '待审核'
      },
      {
         state: '1',
        label: '审核通过'
      },
      {
         state: '-1',
        label: '审核拒绝'
      }],
      userInfo: this.$store.state.localData.userInfo,
      tableData: [],
      RefusetableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      waitDataTotal:0,
      doctorDialogVisible:false,
      doctorDialogProgress:1,
      indexTypeDoctor: 0,
      auditkeyWord: "",
      depvalue: "",
      depoptions: [],
      priceKeyWordmin:'0',
      priceKeyWordmax:'0',
      AuditDoctorList: [],
      auditDetail:{},
      charge: {
        id: 0,
        details: [],
        discountsMoney: 0, //优惠金额
        debtUserName: null,
        debtUserPhone: null,
        debtMoney: 0, //挂账总金额
        payWay: 1, //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
        payMoney: 0, //支付金额
        totalMoney: 0,
        payment: null, //输入金额
        change: 0, //找零
        credit: false,
      },
      timer: null,
      timerZ:null,
      time11:null,
      time12:null,
      qRcodeUrl: "",
      qRcodeUrlZ:'',
      UserBalance: {
        assetsBalance: 0,
      },
      assetsBalanceIsTrue: false,
      payAuditloading:false,
      payAuditloadingTrue:false,
      departmentId:0,
      AuditcurrentPage:0,
      auditPageIndex:1,
      auditTotal: 0,
      patientDetail:{},
      waitAuditUserId:0,
      auditDiagnoseMoney:0
    };
  },
  mounted() {
    let activeName = JSON.parse(sessionStorage.getItem("activeName")) + "";
    if (activeName && activeName != "null") {
      sessionStorage.removeItem("activeName");
      this.activeName = activeName;
    }
    this.gettWaitData1()
    this.getList()
  },
  beforeDestroy() {
        clearInterval()
        clearInterval(this.timer);
        clearInterval(this.timerZ);
  },
  methods: {
    addpayVisible(){
      let _this = this;
      _this.payAuditloading = true
      clearInterval(_this.timer);
      clearInterval(_this.timerZ);
      setTimeout(()=>{
      let form = {
        id: _this.charge.id,
        details: _this.charge.details,
        paymentType:2,
        payments: [
          {
            discountsMoney: _this.charge.discountsMoney,
            debtUserName: _this.charge.debtUserName,
            debtUserPhone: _this.charge.debtUserPhone,
            debtMoney: _this.charge.debtMoney,
            payWay: _this.charge.payWay,
            payMoney: (_this.charge.totalMoney - _this.charge.totalPayMoney).toFixed(2) * 1,
            totalMoney: (_this.charge.totalMoney - _this.charge.totalPayMoney).toFixed(2) * 1
          },
        ],
      };
      if (_this.charge.payWay == 0) {
        _this.outpatientAuthorizationDomain.getIsSetPayPassword(
          function (data) {
            _this.outpatientAuthorizationDomain.getIsPayPassword(
              {
                payPassword: _this.charge.payPwd,
              },
              function (data) {
                _this.OutpatientDomain.DrugPayBalancePayment(form, function (data) {
                  _this.$message({
                    type: "success",
                    message: "支付成功!",
                  });
                  _this.doctorDialogVisible = false
                 _this.getList()
                });
              },
              function (error) {
                _this.$message({
                  type: "info",
                  message: "支付密码错误",
                });
              }
            );
          },
          function (error) {
            _this
              .$confirm("未设置支付密码？去设置", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                const href = _this.$router.resolve("../personal/resetPassword");
                window.open(href.href, "_blank");
              });
          }
        );
      } else if (_this.charge.payWay == 1) {
        _this.OutpatientDomain.DrugPayMent(
          form,
          function (data) {
            // loading.close();
            // _this.qRcodeUrl = _this.Services.Drug + data.data.qRcodeUrl;
            _this.qRcodeUrl = 'data:image/jpeg;base64,' + data.data.qRcodeBase64;
            _this.weChatInfo = data.data;
            var isSuccess = false;
            _this.timer = window.setInterval(() => {
              setTimeout(function () {
                _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                  _this.weChatInfo,
                  function (data2) {
                    isSuccess = data2.data.isSuccess;
                    if (isSuccess) {
                      clearInterval(_this.timer);
                      _this.$message({
                        type: "success",
                        message: "支付成功!",
                      });
                      _this.doctorDialogVisible = false
                    _this.getList()
                      
                      // _this.chargedialogVisible = false
                      // _this.$router.go(-1);
                    }
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              }, 0);
            }, 5000);
            // setTimeout(() => {
            //     if (!isSuccess) {
            //         clearInterval(timer)
            //         if (paymentDialog.value) {
            //             proxy.$message({
            //                 type: 'error',
            //                 message: '支付超时!'
            //             });
            //             getList();
            //         }
            //     }
            // }, 300000)
          },
          function (error) {
            // loading.close();
            let jsonObj = JSON.parse(error.msg);
            _this.$message({
              message: jsonObj.msg,
              type: "error",
            });
            console.log(error);
          }
        );
      } else  {
        _this.OutpatientDomain.DrugPayMent(
          form,
          function (data) {
            // loading.close();
            _this.qRcodeUrlZ = data.data.alipayData;
            _this.weChatInfo = data.data;
            var isSuccess = false;
            _this.timerZ = window.setInterval(() => {
              setTimeout(function () {
                _this.UserDistributorltemDrugMain.VerificationSuccessPay(
                  // _this.weChatInfo,
                  {orderNo: _this.weChatInfo.orderNo,
                    attach: "MZPayment"
                  },
                  function (data2) {
                    isSuccess = data2.data.isSuccess;
                    if (isSuccess) {
                      clearInterval(_this.timerZ);
                      _this.$message({
                        type: "success",
                        message: "支付成功!",
                      });
                      _this.doctorDialogVisible = false
                     _this.getList()
                      // _this.chargedialogVisible = false
                      // _this.$router.go(-1);
                    }
                  },
                  function (error) {
                    console.log(error);
                  }
                );
              }, 0);
            }, 5000);
            // setTimeout(() => {
            //     if (!isSuccess) {
            //         clearInterval(timer)
            //         if (paymentDialog.value) {
            //             proxy.$message({
            //                 type: 'error',
            //                 message: '支付超时!'
            //             });
            //             getList();
            //         }
            //     }
            // }, 300000)
          },
          function (error) {
            // loading.close();
            let jsonObj = JSON.parse(error.msg);
            _this.$message({
              message: jsonObj.msg,
              type: "error",
            });
            console.log(error);
          }
        );
      }
        _this.payAuditloading = false
      },1000)
    },
    adddialogVisible() {
      var _this = this;
      if (_this.waitAuditUserId == "0") {
        _this.$message({
          message: "暂未选择审核人",
          type: "error",
        });
        return;
      }
      if (_this.auditDiagnoseMoney == 0) {
        _this.goGetAuditPayment0()
      } else {
        _this.doctorDialogProgress = 3
        _this.goGetAuditPayment()
      }
    },
    firstDialogVisible(){
      var _this = this
      // _this.OutpatientDomain.cacleMZPrescriptionDetail(
      //   _this.charge.id,
      //   function (data) {
      //       _this.doctorDialogProgress = 1
      //   },
      //   function (err) {
      //     console.log(err);
      //   }
      // );
      _this.doctorDialogProgress = 1
    },
    goGetAuditPayment0(){
      var _this = this
        let params = {
            OrgId: _this.userInfo.organizationId,
            OrgName: _this.userInfo.organizationName,
            PatientId: _this.patientDetail.patientId,
            PatientName: _this.patientDetail.patientName,
            DoctorId: _this.userInfo.id,
            DoctorName: _this.userInfo.name,
            DepartmentId:_this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
            DepartmentName:_this.userInfo.department ? _this.userInfo.department : '',
            DoctorPhone:_this.userInfo.phone,
            PatientDiagnoseId: _this.patientDetail.patientDiagnoseId,
            DiagnoseResult: _this.patientDetail.diseaseDiagnose,
            OrderType: 1,
            TotalMoney: 0,
            Details: [],
            auditUserId:_this.auditDetail.id,
            auditUserName:_this.auditDetail.userName,
            auditUserPhone:_this.auditDetail.phone,
            auditOrgId:_this.auditDetail.organizationId,
            auditOrgName: _this.auditDetail.organization.name,
            auditDoctorType: _this.indexTypeDoctor
          };
          params.Details.push({
            OrderType: 5,
            EntityName: '医生审方费用',
            Amount: 1,
            Unit: "次",
            Price: _this.auditDiagnoseMoney,
            Money: _this.auditDiagnoseMoney,
            CountMoney: _this.auditDiagnoseMoney,
            OrgId:_this.auditDetail.organizationId
          });
          for(let i=0;i<params.Details.length;i++) {
          params.TotalMoney += params.Details[i].CountMoney;
        }
        params.TotalMoney =  params.TotalMoney.toFixed(2)
         _this.drugpatientDomain.GetAuditPayment(
            params,
            function (data) {
              _this.charge = data.data;
              _this.charge.payMoney = data.data.totalMoney;
              _this.doctorDialogVisible = false
              _this.getList()
            },
            function (error) {
              console.log(error);
          }
        );
    },
    goGetAuditPayment(){
      var _this = this
        let params = {
            OrgId: _this.userInfo.organizationId,
            OrgName: _this.userInfo.organizationName,
            PatientId: _this.patientDetail.patientId,
            PatientName: _this.patientDetail.patientName,
            DoctorId: _this.userInfo.id,
            DoctorName: _this.userInfo.name,
            DepartmentId:_this.userInfo.departmentId ? _this.userInfo.departmentId : 0,
            DepartmentName:_this.userInfo.department ? _this.userInfo.department : '',
            DoctorPhone:_this.userInfo.phone,
            PatientDiagnoseId: _this.patientDetail.patientDiagnoseId,
            DiagnoseResult: _this.patientDetail.diseaseDiagnose,
            OrderType: 1,
            TotalMoney: 0,
            Details: [],
            auditUserId:_this.auditDetail.id,
            auditUserName:_this.auditDetail.userName,
            auditUserPhone:_this.auditDetail.phone,
            auditOrgId:_this.auditDetail.organizationId,
            auditOrgName: _this.auditDetail.organization.name,
            auditDoctorType: _this.indexTypeDoctor
          };
          params.Details.push({
            OrderType: 5,
            EntityName: '医生审方费用',
            Amount: 1,
            Unit: "次",
            Price: _this.auditDiagnoseMoney,
            Money: _this.auditDiagnoseMoney,
            CountMoney: _this.auditDiagnoseMoney,
            OrgId:_this.auditDetail.organizationId
          });
          for(let i=0;i<params.Details.length;i++) {
          params.TotalMoney += params.Details[i].CountMoney;
        }
        params.TotalMoney =  params.TotalMoney.toFixed(2)
         _this.drugpatientDomain.GetAuditPayment(
            params,
            function (data) {
              _this.charge = data.data;
              _this.charge.payMoney = data.data.totalMoney;
              _this.getAllUserBalance();
            },
            function (error) {
              console.log(error);
          }
        );
    },
    getAllUserBalance() {
      var _this = this;
      _this.UserDistributorMain.getUserDistributor(
        function (data) {
          _this.UserBalance = data.data;
          if (_this.UserBalance.assetsBalance * 1 < _this.charge.totalMoney * 1) {
            _this.assetsBalanceIsTrue = true;
            _this.charge.payWay = 1;
          } else {
            _this.assetsBalanceIsTrue = false;
          }
        },
        function (err) {
          _this.UserBalance = [];
          console.log(err);
        }
      );
    },
    cancledialogVisible(){
       var _this = this
      _this.auditDetail = {}
      _this.waitAuditUserId = 0;
      _this.auditDiagnoseMoney = 0;
      _this.doctorDialogProgress = 1
      // _this.AuditNextDataSuc = false
      _this.doctorDialogVisible = false
    },
    cancledialogVisible1(){
      var _this = this
      // _this.OutpatientDomain.cacleMZPrescriptionDetail(
      //   _this.charge.id,
      //   function (data) {
      //       _this.$message({
      //         type: "error",
      //         message: "取消支付!",
      //       });
      //     _this.auditDetail = {}
      //     _this.waitAuditUserId = 0;
      //     _this.auditDiagnoseMoney = 0;
      //     _this.doctorDialogProgress = 1
      //     _this.doctorDialogVisible = false
      //   },
      //   function (err) {
      //     console.log(err);
      //   }
      // );
              _this.auditDetail = {}
          _this.waitAuditUserId = 0;
          _this.auditDiagnoseMoney = 0;
          _this.doctorDialogProgress = 1
          _this.doctorDialogVisible = false
    },
    getPayPwd() {
      const href = this.$router.resolve("../personal/resetPassword");
      window.open(href.href, "_blank");
    },
    chargePayWayInput(e){
      clearInterval(this.timer);
      clearInterval(this.timerZ);
      this.qRcodeUrl = ''
      this.qRcodeUrlZ = ''
    },
    AuditCurrentChange(pageNum) {
      this.AuditcurrentPage = pageNum;
      this.auditPageIndex = pageNum;
      if (this.indexTypeDoctor == 0) {
        this.GetAuditDoctorData()
      } else {
        this.GetPlantformExpertDoctorData()
      }
      // this.GetAuditDoctorData();
    },
    handleSelectionChangeAudit(val) {
      var _this = this;
      _this.auditDetail = val
      _this.waitAuditUserId = val.id;
      _this.auditDiagnoseMoney = val.auditDiagnoseMoney;
    },
    auditkeySearch() {
      if (this.indexTypeDoctor == 0) {
        this.GetAuditDoctorData()
      } else {
        this.GetPlantformExpertDoctorData()
      }
    },
    depvalueChange(e) {
      this.departmentId = e;
    },
    auditkeyDoctor(indexType) {
      this.auditDetail = {}
      this.indexTypeDoctor = indexType
      if (indexType == 0) {
        this.auditkeyOption();
        this.GetAuditDoctorData()
      } else {
        this.auditkeyOption1();
        this.GetPlantformExpertDoctorData()
      }
    },
    auditkeyOption() {
      var _this = this;
      _this.patientDomain.departmentDoctor(
        _this.userInfo.organizationId,
        function (data) {
          _this.depoptions = data.data;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    auditkeyOption1() {
      var _this = this;
      _this.patientDomain.departmentDoctor(
        '130',
        function (data) {
          _this.depoptions = data.data;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    GetAuditDoctorData() {
      var _this = this;
      _this.patientDomain.GetAuditDoctor(
        _this.auditkeyWord,
        _this.departmentId ? _this.departmentId : "0",
        _this.auditPageIndex,
        _this.indexTypeDoctor.toString(),
        _this.priceKeyWordmin.toString(),
        _this.priceKeyWordmax.toString(),
        function (data) {
          let paramsrList = []
          paramsrList = data.data.results;
          let params = []
          paramsrList.map(res => {
            params.push(res.id)
          })
          _this.drugpatientDomain.GetDoctorAuditCountData(
            params.toString(),
            function (data) {
              for (let i = 0; i < paramsrList.length; i++) {
                for (let j = 0; j < data.data.length; j++) {
                  if (paramsrList[i].id == data.data[j].userId) {
                    paramsrList[i]['auditCount'] = data.data[j].waitAuditCount
                  }
                }
              }
              _this.AuditDoctorList = paramsrList
            },
            function (error) {
              console.log(error);
              _this.AuditDoctorList = [];
            }
          );
          _this.auditTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
          // _this.auditkeyOption();
          _this.AuditDoctorList = [];
        }
      );
    },
    GetPlantformExpertDoctorData() {
      var _this = this;
      _this.patientDomain.GetPlantformExpertDoctor(
        _this.auditkeyWord,
        _this.departmentId ? _this.departmentId : "0",
        _this.auditPageIndex,
        _this.priceKeyWordmin.toString(),
        _this.priceKeyWordmax.toString(),
        function (data) {
          let paramsrList = []
          paramsrList = data.data.results;
          let params = []
          paramsrList.map(res => {
            params.push(res.id)
          })
          _this.drugpatientDomain.GetDoctorAuditCountData(
            params.toString(),
            function (data) {
              for (let i = 0; i < paramsrList.length; i++) {
                for (let j = 0; j < data.data.length; j++) {
                  if (paramsrList[i].id == data.data[j].userId) {
                    paramsrList[i]['auditCount'] = data.data[j].waitAuditCount
                  }
                }
              }
              _this.AuditDoctorList = paramsrList
            },
            function (error) {
              console.log(error);
              _this.AuditDoctorList = [];
            }
          );
          _this.auditTotal = data.data.dataTotal;
        },
        function (error) {
          console.log(error);
          // _this.auditkeyOption();
          _this.AuditDoctorList = [];
        }
      );
    },


    Search() {
        switch (this.activeName) {
        case "1":
          this.getData();
          break;
        case "2":
          this.gettWaitData();
          break;
      }
    },
    gettWaitData1() {
      let _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
        },
        function (error) {
          _this.waitDataTotal = 0;
        }
      );
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    },
    getList() {
      switch (this.activeName) {
        case "1":
        this.searchForm = {
            datepicker: "",
            keyWord: "",
            state:'-999'
          };
          this.getData();
          break;
        case "2":
          this.searchForm = {
            datepicker: "",
            keyWord: "",
            state:'-999'
          };
          this.gettWaitData();
          break;
      }
    },
    handleCurrentChange(pageNum) {
      this.patientcurrentPage = pageNum;
      this.pageIndex = pageNum;
      this.getData();
    },
    RefuCurrentChange(pageNum) {
      this.RefucurrentPage = pageNum;
      this.RefusepageIndex = pageNum;
      this.gettWaitData();
    },
    goDoctor() {
      if (this.userInfo.isAuditDiagnose == "True") {
        this.$router.push({
          name: "PremangePatientList",
        });
      } else {
        this.$message({
          message: "暂无审方权限,请联系管理员添加",
          type: "warning",
        });
      }
      // this.doctorDialogVisible = true
      // this.GetAuditDoctorData()
    },
    handleClick(e) {
      this.pageIndex = 1;
      this.dataTotal = 0;
      this.searchForm = {
        datepicker: "",
        keyWord: "",
        state:'-999'
      };
      this.getList();
    },
    getData() {
      var _this = this;
      let nowtime = 20 * 60 * 1000;
      // let nowtime1 = new Date().getTime();
      let state = _this.searchForm.state;
      let startTime = _this.searchForm.datepicker ? _this.searchForm.datepicker[0]: '%20'
      let endTime = _this.searchForm.datepicker ? _this.searchForm.datepicker[1] + ' 23:59:59': '%20'
      let keyWord = _this.searchForm.keyWord ? _this.searchForm.keyWord : '%20'
      _this.tableData = [];
      _this.drugpatientDomain.getRefusePrescription(
        state,
        startTime,
        endTime,
        keyWord,
        _this.pageIndex,
        function (data) {
        for (let i=0; i<data.data.results.length; i++) {
            if (data.data.results[i].auditStatus == 0) {
              if (data.data.results[i].time12) {
                clearInterval(data.data.results[i].time12)
              }
              var f = data.data.results[i].addTime.split('T')
              var timeChuEnd = (nowtime + new Date(f[0] + ' ' + f[1].slice(0,8)).getTime())
              var timeChu = new Date(f[0] + ' ' + f[1].slice(0,8)).getTime()
              // 结束时间
                data.data.results[i].SYAuditTimeEnd = timeChuEnd
                data.data.results[i].SYAuditTimetimeChu = timeChu
                if ((new Date().getTime() - data.data.results[i].SYAuditTimetimeChu) > nowtime) {
                  data.data.results[i].SYAuditTime = ''
                } else {
                  data.data.results[i].setEnd = parseInt((data.data.results[i].SYAuditTimeEnd - new Date().getTime()) / 1000)
                  data.data.results[i].SYAuditTime = _this.getMilliSecond(data.data.results[i].setEnd)
                  data.data.results[i].time12 = null
                   data.data.results[i].time12 = setInterval(()=>{
                      if (data.data.results[i].setEnd < 1) {
                          clearInterval(data.data.results[i].time12)
                          _this.getData()
                      } else {
                        data.data.results[i].setEnd = data.data.results[i].setEnd - 1
                        data.data.results[i].SYAuditTime = _this.getMilliSecond(data.data.results[i].setEnd)
                      }
                  },1000)
                }
            }
          }
          _this.tableData = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (error) {
          _this.tableData = [];
        }
      );
    },
    
    getMilliSecond(seconds){
        let secondTime = parseInt(seconds); // 秒
        let minuteTime = 0; // 分
        // let hourTime = 0; // 时
        if (secondTime > 60) { //如果秒数大于60，将秒数转换成整数
            //获取分钟，除以60取整数，得到整数分钟
            minuteTime = parseInt(secondTime / 60);
            //获取秒数，秒数取佘，得到整数秒数
            secondTime = parseInt(secondTime % 60);
            //如果分钟大于60，将分钟转换成小时
            // if (minuteTime > 60) {
            //     //获取小时，获取分钟除以60，得到整数小时
            //     hourTime = parseInt(minuteTime / 60);
            //     //获取小时后取佘的分，获取分钟除以60取佘的分
            //     minuteTime = parseInt(minuteTime % 60);
            // }
        }
        let result = '';
        if (secondTime >= 0 && secondTime < 10) {
            result = "0" + parseInt(secondTime) + "";
        } else {
            result = "" + parseInt(secondTime) + "";
        }
        if (minuteTime >= 0 && minuteTime < 10) {
            result = "0" + parseInt(minuteTime) + ":" + result;
        } else {
            result = "" + parseInt(minuteTime) + ":" + result;
        }
        // if (hourTime >= 0 && hourTime < 10) {
        //     result = "0" + parseInt(hourTime) + ":" + result;
        // } else {
        //     result = "" + parseInt(hourTime) + ":" + result;
        // }
        return result;
    },
    gettWaitData() {
      var _this = this;
      let nowtime1 = new Date().getTime();
      // // 半个小时倒计时
      // let nowtime = 30 * 60 * 1000;
       // 3分钟倒计时
      let nowtime = 20 * 60 * 1000;
       let state = _this.searchForm.state;
      let startTime = _this.searchForm.datepicker ? _this.searchForm.datepicker[0] : '%20'
      let endTime = _this.searchForm.datepicker ? _this.searchForm.datepicker[1] + ' 23:59:59': '%20'
      let keyWord = _this.searchForm.keyWord ? _this.searchForm.keyWord : '%20'
      _this.tableData = [];
      _this.drugpatientDomain.getWaitAuditPrescription(
        state,
        startTime,
        endTime,
        keyWord,
        _this.pageIndex,
        function (data) {
          for (let i=0; i<data.data.results.length; i++) {
            if (data.data.results[i].auditStatus == 0) {
              if (data.data.results[i].time11) {
                clearInterval(data.data.results[i].time11)
              }
              var f = data.data.results[i].addTime.split('T')
              var timeChuEnd = (nowtime + new Date(f[0] + ' ' + f[1].slice(0,8)).getTime())
              var timeChu = new Date(f[0] + ' ' + f[1].slice(0,8)).getTime()
              // 结束时间
              data.data.results[i].SYAuditTimeEnd = timeChuEnd
              data.data.results[i].SYAuditTimetimeChu = timeChu
                if ((nowtime1 - data.data.results[i].SYAuditTimetimeChu) > nowtime) {
                  data.data.results[i].SYAuditTime = ''
                } else {
                  data.data.results[i].setEnd = parseInt((data.data.results[i].SYAuditTimeEnd - new Date().getTime()) / 1000)
                  data.data.results[i].SYAuditTime = _this.getMilliSecond(data.data.results[i].setEnd)
                  data.data.results[i].time11 = null
                   data.data.results[i].time11 = setInterval(()=>{
                      if (data.data.results[i].setEnd < 1) {
                          clearInterval(data.data.results[i].time11)
                          _this.gettWaitData()
                      } else {
                        data.data.results[i].setEnd = data.data.results[i].setEnd - 1
                        data.data.results[i].SYAuditTime = _this.getMilliSecond(data.data.results[i].setEnd)
                      }
                  },1000)
                }
            }
          }
          _this.tableData = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (error) {
          _this.tableData = [];
        }
      );
    },
    goseeDoctor(item) {
      sessionStorage.setItem("activeName", "1");
      let isFalse = false;
      if (item.auditStatus == 0) {
        isFalse = true;
      } else {
        isFalse = false;
      }
      this.$router.push({
        name: "PremangeseeDtail",
        query: {
          id: item.patientDiagnoseId,
          type: "ziji",
          isFalse1: isFalse,
        },
      });
    },
    goseeDoctor2(item){
      sessionStorage.setItem("activeName", "2");
      let isFalse = false;
      if (item.auditStatus == 0) {
        isFalse = true;
      } else {
        isFalse = false;
      }
      this.$router.push({
        name: "PremangeseeDtail",
        query: {
          id: item.patientDiagnoseId,
          isFalse1: isFalse,
          isSrart:"True"
        },
      });
    },
    goseeAuidtDoctor(item){
      console.log(item)
      // 重新审方
      var _this = this
      _this.patientDetail = item
     _this.auditkeyWord = "", _this.departmentId = 0, _this.GetAuditDoctorData();
      _this.auditDetail = {}
      _this.waitAuditUserId = 0;
      _this.auditDiagnoseMoney = 0;
      _this.doctorDialogProgress = 1
      _this.doctorDialogVisible = true;
    },
    goseeDoctor1(item) {
      sessionStorage.setItem("activeName", "2");
      let isFalse = false;
      if (item.auditStatus == 0) {
        isFalse = true;
      } else {
        isFalse = false;
      }
      this.$router.push({
        name: "PremangeseeDtail",
        query: {
          id: item.patientDiagnoseId,
          isFalse1: isFalse,
        },
      });
    },
    goBack() {
      this.addtoNew = false;
    },
    gonewDoctor() {
      this.addtoNew = !this.addtoNew;
    },
    handleClickTop(e, e1) {
      if (e.label == "患者病历") {
        this.$router.push({
          name: "interrogationIndex",
        });
      }
      if (e.label == "患者列表") {
        this.$router.push({
          name: "intPatientList",
        });
      }
      if (e.label == "个人常用处方") {
        this.$router.push({
          name: "preintPatientList",
        });
      }
      if (e.label == "处方管理") {
        this.$router.push({
          name: "PremangePatientList",
        });
      }
    },
  },
};
</script>
<style scoped>
.pageBox {
  text-align: center;
  margin-top: 10px;
}
.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}
.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}
.titleTopLeftImg {
  width: 123px;
  height: 24px;
}
.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}
.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}
.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}
.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}
.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.titleTopRight div {
  margin-left: 10px;
}
.titleTopLine {
  width: 100%;
  height: 12px;
}
.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}
.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  /* max-height: 750px; */
  overflow: auto;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  /* margin-top: 0; */
}
.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  height: calc(100vh - 64px - 24px);
  overflow: auto;
}
.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding: 0 40px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  height: 100%;
}
.flex-l {
  display: flex;
  align-items: center;
}
.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Content1Title {
  padding: 20px 0;
  padding-left: 0;
  font-size: 18px;
  height: calc(100% - 20px);
  /* font-weight: bold; */

  color: #333333;
  padding-bottom: 0;
  text-align: left;
}
.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}
.ageInput {
  width: 80%;
}
.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}
.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.table3Box {
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  padding: 30px;
  margin-top: 10px;
}
.titleTopright1Line {
  border-bottom: 1px dashed #999999;
}
.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}
.stepInput {
  width: 60%;
  margin-right: 10px;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
::v-deep .el-pagination {
  padding: 10px 5px;
}
.flex-l-b1 {
  display: flex;
  justify-content: space-between;
}
.noImgData {
  width: 285px;
  height: 164px;
}

.noImgData img {
  width: 100%;
  height: 100%;
}
.elImagePho {
  width: 60px;
  height: 60px;
}

.elImagePho img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
</style>
